@keyframes pageTitle {
  from {
    transform: translateY(50px);
    opacity: 0.5;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes pageTitle1 {
  from {opacity: 0;}
  to {opacity: 1;}
}

.sub-header {
  position: relative;
  height: 250px;
  padding-top: 100px;
  border-bottom: 1px solid #eee;
  overflow: hidden;

  .page-title {
    text-align: center;
    .depth1 {
      font-size: 14px;
      font-weight: 500;
      color: #777;
      letter-spacing: -1px;
      animation: pageTitle1  1s ease;
    }
    .depth2 {
      font-size: 44px;
      font-weight: bold;
      letter-spacing: -3px;
      margin: 10px 0 55px;
      animation: pageTitle  1s ease;
    }
  }
  .path {
    li {
      float: left;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: -1px;
      color: #777;
      &:not(:last-child):after {
        content: ">";
        padding: 0.625em;
      }
      .home {
        color: #777;
        padding-left: 15px;
        background: url('/assets/images/subheader/icon_home.png') no-repeat 0 50%;
      }
      &.depth2 {
        color: #222;
      }
    }
  }
  &.member {
    height: 460px;
    border-bottom: none;
    z-index: -1;
    .path {display: none;}
  }
}

@media screen and (max-width:750px) {
  .sub-header {
    height: 43.8rem;
    padding-top: 16rem;
    border-bottom: 1px solid #eee;
  
    .page-title {
      text-align: center;
      .depth1 {
        font-size: 2.2rem;
        font-weight: 500;
        color: #777;
        letter-spacing: -1px;
      }
      .depth2 {
        font-size: 7.2rem;
        font-weight: bold;
        letter-spacing: -4px;
        margin: 3rem 0 0;
      }
    }
    .path {
      display: none;
    }
    &.member {
      height: 46rem;
    }
  }
}  