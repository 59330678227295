.inspection {
  .inspection-wrap {
    padding: 100px 0 30px;
    .mobile-only {display: none;}
  }
  .guide-box {
    padding: 60px 60px 55px 190px;
    margin-bottom: 100px;
    background: #f6f6f6 url('/assets/images/inspection/icon_guide.png') no-repeat 60px 50%;
    border-radius: 0 30px 30px 30px;
    .title {
      font-size: 17px;
      font-weight: bold;
      color: #004896;
      letter-spacing: -1px;
      padding-bottom: 15px;
    }
    .txt {
      font-size: 14px;
      font-weight: 500;
      color: #777;
      letter-spacing: -1px;
      line-height: 24px;
    }
  }
  .section {
    padding: 0 0 100px;
    .section-title {
      font-size: 20px;
      font-weight: bold;
      letter-spacing: -1px;
      padding-bottom: 25px;
    }
    .section-txt {
      font-size: 17px;
      line-height: 27px;
      letter-spacing: -1px;
    }
    .sub-title {
      font-size: 17px;
      color: #5b646e;
      letter-spacing: -1px;
      padding: 30px 0;
    }
    .list-table {
      margin-bottom: 20px;
    }
    .process1 {
      li {
        float: left;
        padding: 0 100px 55px 0;
        background: url('/assets/images/inspection/icon_right.png') no-repeat right 42px top 51px;

        > div {
          display:flex;
          align-items: center;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: -1px;
          line-height: 24px;
          width: 332px;
          height: 130px;
          padding: 0 30px 0 125px;
          border-radius: 0 40px 40px 40px;
        }
        &:nth-child(1) > div {background: #f6f6f6 url('/assets/images/inspection/icon_process1_01.png') no-repeat 30px 50%;}
        &:nth-child(2) > div {background: #f6f6f6 url('/assets/images/inspection/icon_process1_02.png') no-repeat 30px 50%;}
        &:nth-child(3) > div {background: #f6f6f6 url('/assets/images/inspection/icon_process1_03.png') no-repeat 30px 50%;}
        &:nth-child(4) > div {background: #f6f6f6 url('/assets/images/inspection/icon_process1_04.png') no-repeat 30px 50%;}
        &:nth-child(5) > div {background: #f6f6f6 url('/assets/images/inspection/icon_process1_05.png') no-repeat 30px 50%;}
        &:nth-child(6) > div {background: #f6f6f6 url('/assets/images/inspection/icon_process1_06.png') no-repeat 30px 50%;}
        &:nth-child(7) > div {background: #f6f6f6 url('/assets/images/inspection/icon_process1_07.png') no-repeat 30px 50%;}
        &:nth-child(8) > div {background: #f6f6f6 url('/assets/images/inspection/icon_process1_08.png') no-repeat 30px 50%;}

        &:nth-child(3), &:nth-child(6) {
          padding: 0 0 55px;
          background: url('/assets/images/inspection/icon_down.png') no-repeat center bottom 20px;
        }
        &:nth-child(4), &:nth-child(5) {
          float: right;
          padding: 0 0 55px 100px;
          background: url('/assets/images/inspection/icon_left.png') no-repeat left 42px top 51px;
        }
        &:nth-child(6) {
          float: right;
        }
        &:nth-child(8) {
          padding: 0;
          background: none;
        }
      }
    }
    .process2 {
      padding-bottom: 50px;
      li {
        float: left;
        padding: 0 25px 20px 0;
        background: url('/assets/images/inspection/icon_right1.png') no-repeat right 8px top 40px;

        > div:nth-child(1) {
          display:flex;
          justify-content: center;
          align-items: center;
          font-size: 17px;
          font-weight: 500;
          color: #5b646e;
          letter-spacing: -1px;
          line-height: 20px;
          text-align: center;
          width: 220px;
          height: 90px;
          background: #e5f1fb;
          border-radius: 0 25px 25px 25px;
          p {
            font-size: 17px;
            font-weight: 500;
            color: #5b646e;
            .sub {
              font-size: 14px;
              color: #5b646e;
            }
          }
        }
        > div:nth-child(2) {
          font-size: 12px;
          font-weight: 500;
          color: #777;
          letter-spacing: -1px;
          text-align: center;
          padding-top: 10px;
        }
        &:nth-last-child(1) {
          padding: 0;
          background: none;
        }
      }
    }
    .info-ul {
      li {
        position: relative;
        font-size: 17px;
        letter-spacing: -1px;
        line-height: 48px;
        padding-left: 150px;
        margin-bottom: 10px;
        .name {
          position: absolute;
          left: 0;
          display: inline-block;
          width: 130px;
          height: 48px;
          font-size: 14px;
          font-weight: 500;
          color: #004896;
          letter-spacing: -1px;
          line-height: 48px;
          text-align: center;
          border: 1px solid #e2e8f3;
          border-radius: 0 10px 10px 10px;
          margin-right: 20px;
        }
      }
    }
    .infobox {
      font-size: 14px;
      letter-spacing: -1px;
      line-height: 30px;
      padding: 40px;
      border: 1px solid #e2e8f3;
      margin-bottom: 100px;
    }
  }
}

@media screen and (max-width:1300px) {
  .inspection {
    .inspection-wrap {
      padding: 170px 40px 30px;
    }
    .section {
      .process1 {
        width: 332px;
        margin: 0 auto;
        li {
          float: none;
          &:nth-child(n) {
            padding: 0 0 55px;
            background: url('/assets/images/inspection/icon_down.png') no-repeat center bottom 20px;
          }
          &:nth-child(8) {
            padding: 0;
            background: none;
          }
        }
      }
    }
  }
}

@media screen and (max-width:750px) {
  .inspection {
    .inspection-wrap {
      padding: 17rem 4.8rem 5rem;
      .pc-only {display: none;}
      .mobile-only {display: block;}
    }
    .guide-box {
      padding: 3.5rem 4rem;
      margin-bottom: 10rem;
      background: #f6f6f6;
      border-radius: 0 3rem 3rem 3rem;
      .title {
        font-size: 2.8rem;
        font-weight: bold;
        color: #004896;
        letter-spacing: -1px;
        line-height: 4rem;
        padding-bottom: 3rem;
      }
      .txt {
        font-size: 2.4rem;
        font-weight: 500;
        color: #777;
        letter-spacing: -1px;
        line-height: 3.6rem;
      }
    }
    .section {
      padding: 0 0 10rem;
      .section-title {
        font-size: 3rem;
        font-weight: bold;
        letter-spacing: -2px;
        line-height: 3.7rem;
        padding-bottom: 3rem;
      }
      .section-txt {
        font-size: 2.4rem;
        line-height: 3.8rem;
        letter-spacing: -1px;
      }
      .sub-title {
        font-size: 2.4rem;
        color: #5b646e;
        letter-spacing: -1px;
        padding: 3rem 0;
      }
      .list-table {
        margin-bottom: 2rem;
        &.mobile-only {display: table;}
      }
      .process1 {
        width: 100%;
        li {
          &:nth-child(n) {
            float: none;
            padding: 0 0 8rem;
            background: url('/assets/images/inspection/icon_down.png') no-repeat center bottom 3rem / 2.8rem auto;
          }
          > div {
            display:flex;
            align-items: center;
            font-size: 2.4rem;
            font-weight: 500;
            letter-spacing: -1px;
            line-height: 3.4rem;
            width: 100%;
            height: 13rem;
            padding: 0 3rem 0 14rem;
            border-radius: 0 5rem 5rem 5rem;
          }
          &:nth-child(1) > div {background: #f6f6f6 url('/assets/images/inspection/icon_process1_01.png') no-repeat 3rem 50% / 8rem;}
          &:nth-child(2) > div {background: #f6f6f6 url('/assets/images/inspection/icon_process1_02.png') no-repeat 3rem 50% / 8rem;}
          &:nth-child(3) > div {background: #f6f6f6 url('/assets/images/inspection/icon_process1_03.png') no-repeat 3rem 50% / 8rem;}
          &:nth-child(4) > div {background: #f6f6f6 url('/assets/images/inspection/icon_process1_04.png') no-repeat 3rem 50% / 8rem;}
          &:nth-child(5) > div {background: #f6f6f6 url('/assets/images/inspection/icon_process1_05.png') no-repeat 3rem 50% / 8rem;}
          &:nth-child(6) > div {background: #f6f6f6 url('/assets/images/inspection/icon_process1_06.png') no-repeat 3rem 50% / 8rem;}
          &:nth-child(7) > div {background: #f6f6f6 url('/assets/images/inspection/icon_process1_07.png') no-repeat 3rem 50% / 8rem;}
          &:nth-child(8) > div {background: #f6f6f6 url('/assets/images/inspection/icon_process1_08.png') no-repeat 3rem 50% / 8rem;}

          &:nth-child(8) {
            padding: 0;
            background: none;
          }
        }
      }
      .process2 {
        padding-bottom: 7rem;
        li {
          float: none;
          padding: 0 0 8rem;
          background: url('/assets/images/inspection/icon_down1.png') no-repeat center bottom 3rem / 2.8rem auto;

          > div:nth-child(1) {
            display:flex;
            justify-content: center;
            align-items: center;
            font-size: 2.4rem;
            font-weight: 500;
            color: #5b646e;
            letter-spacing: -1px;
            line-height: 20px;
            text-align: center;
            width: 100%;
            height: 9rem;
            background: #e5f1fb;
            border-radius: 0 3rem 3rem 3rem;
            p {
              font-size: 2.4rem;
              font-weight: 500;
              color: #5b646e;
              .sub {
                font-size: 2.4rem;
                color: #5b646e;
              }
            }
          }
          > div:nth-child(2) {
            font-size: 2rem;
            font-weight: 500;
            color: #777;
            letter-spacing: -1px;
            text-align: center;
            padding-top: 2rem;
          }
          &:nth-last-child(1) {
            padding: 0;
            background: none;
          }
        }
      }
      .info-ul {
        li {
          position: relative;
          font-size: 2.4rem;
          letter-spacing: -1px;
          line-height: 4.8rem;
          padding-left: 15rem;
          margin: 4rem 0 2rem;
          .name{
            position: absolute;
            left: 0;
            display: inline-block;
            width: 13rem;
            height: 4.8rem;
            font-size: 2.4rem;
            font-weight: 500;
            color: #004896;
            letter-spacing: -1px;
            line-height: 4.8rem;
            text-align: center;
            border: 1px solid #e2e8f3;
            border-radius: 0 1rem 1rem 1rem;
            margin-right: 2rem;
          }
        }
      }
      .infobox {
        font-size: 2.4rem;
        letter-spacing: -1px;
        line-height: 3.4rem;
        padding: 40px;
        border: 1px solid #e2e8f3;
        margin-bottom: 10rem;
        .cred {
          color: #e40000;
        }
      }
    }
  }
}

.information {
  .information-wrap {
    padding: 100px 0 30px;
    .mobile-only {display: none;}
  }
  .section {
    padding: 0 0 100px;
    .section-title {
      font-size: 20px;
      font-weight: bold;
      letter-spacing: -1px;
      padding-bottom: 25px;
    }
  }
  .list-table {
    .col1 {width: 25%;}
    .col2 {width: 45%;}
    .col3 {width: 30%;}
  }
}

@media screen and (max-width:1300px) {
  .information {
    .information-wrap {
      padding: 170px 40px 30px;
    }
  }
}

@media screen and (max-width:750px) {
  .information {
    .information-wrap {
      padding: 17rem 4.8rem 5rem;
      .pc-only {display: none;}
    }
    .section {
      padding: 0 0 10rem;
      .section-title {
        font-size: 3rem;
        font-weight: bold;
        letter-spacing: -2px;
        line-height: 3.7rem;
        padding-bottom: 3rem;
      }
      .list-table {
        .col1 {width: 0%;}
        .col2 {width: 65%;}
        .col3 {width: 35%;}
        tr {
          &:nth-child(1) > td:nth-child(1) {
            display: hidden;
          }
          .pc-td {
            padding: 0;
            border: none;
          }
        }
        .HBtn {
          border: none;
        }
      }
    }
  }
}

/* 기관소개 - 인사말 */
.greetings {
  .bg {
    height: 375px;
    background-color: #f0f3f9;
    .welcome-txt {
      font-size: 34px;
      font-weight: bold;
      color: #222;
      letter-spacing: -2px;
      line-height: 44px;
      padding-top: 100px;
    }
  }
  .greetings-img {
    max-width: 1200px;
    width: 100%;
    height: 320px;
    background: url('/assets/images/intro/greetings01.png');
    margin-top: -160px;
  }
  .greetings-txt {
    padding: 50px 0 150px;
    .txt {
      font-size: 17px;
      letter-spacing: -1px;
      line-height: 40px;
      .txt1 {
        font-size: 36px;
        color: #00a28d;
        letter-spacing: -2px;
        line-height: 40px;
        margin-bottom: 30px;
      }
    }
    .sign {
      padding-top: 90px;
      .position {
        float: right;
        font-size: 13px;
        font-weight: 500;
        color: #777;
        letter-spacing: -1px;
        text-align: right;
        margin: 35px 20px 0 0;
      }
      .ceo {
        float: right;
        width: 196px;
        height: 54px;
        background: url('/assets/images/intro/ceo_sign.png') 50% / contain;
        text-indent: -9999px;
      }
    }
  }
}

@media screen and (max-width:1200px) {
  .greetings {
    .greetings-txt {
      padding: 110px 40px 150px;
    }
  }
}

@media screen and (max-width:750px) {
  .greetings {
    .bg {
      height: 40rem;
      background-color: #f0f3f9;
      .welcome-txt {
        font-size: 3.4rem;
        font-weight: bold;
        color: #222;
        letter-spacing: -2px;
        line-height: 4.4rem;
        padding-top: 16.5rem;
      }
    }
    .greetings-img {
      width: calc(100% - 9.6rem);
      height: 16.3rem;
      background: url('/assets/images/intro/greetings01.png') 50% / 100%;
      margin-top: -10rem;
    }
    .greetings-txt {
      padding: 10rem 4.8rem 20rem;
      .txt {
        font-size: 2.4rem;
        letter-spacing: -1px;
        line-height: 4rem;
        .txt1 {
          font-size: 3.6rem;
          color: #00a28d;
          letter-spacing: -2px;
          line-height: 4rem;
          margin-bottom: 3rem;
        }
      }
      .sign {
        padding-top: 12rem;
        .position {
          float: right;
          font-size: 1.7rem;
          font-weight: bold;
          color: #777;
          letter-spacing: -1px;
          text-align: right;
          margin: 3.5rem 2rem 0 0;
        }
        .ceo {
          float: right;
          width: 19.6rem;
          height: 5.4rem;
          background: url('/assets/images/intro/ceo_sign.png') 50% / contain;
          text-indent: -9999px;
        }
      }
    }
  }
}

/* 기관소개 - 일반현황 */
.history {
  .bg {
    padding: 100px 0 20px;
    background-color: #f0f3f9;
    .td {
      float: left;
      width: calc(100% - 280px);
      font-size: 17px;
      letter-spacing: -1px;
      line-height: 30px;
      padding-bottom: 80px;
    }
  }
  .th {
    float: left;
    width: 280px;
    font-size: 34px;
    font-weight: bold;
    letter-spacing: -2px;
    line-height: 44px;
  }
  .history-list {
    padding: 100px 0 30px;
    .history-ul {
      float: left;
      width: calc(100% - 280px);
      li {
        .year {
          float: left;
          width: 190px;
          font-family: "Poppins";
          font-size: 30px;
          font-weight: 700;
          color: #00a6bf;
        }
        > ul {
          float: left;
          width: calc(100% - 190px);
          border-top: 1px solid #e3e8f3;
          margin-bottom: 100px;
          li {
            padding: 13px 30px;
            border-bottom: 1px solid #e3e8f3;
            .no {
              float: left;
              width: 100px;
              font-size: 17px;
              font-weight: 500;
              line-height: 30px;
            }
            .txt {
              float: left;
              width: calc(100% - 100px);
              font-size: 17px;
              line-height: 30px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width:1300px) {
  .history {
    .bg {
      .td {
        float: none;
        width: 100%;
      }
    }
    .th {
      float: none;
      width: 100%;
      padding-bottom: 30px;
    }
    .history-list {
      padding: 165px 40px 30px;
      .history-ul {
        float: none;
        width: 100%;
      }
    }
  }
}

@media screen and (max-width:750px) {
  .history {
    .bg {
      padding: 16.5rem 0 8rem;
      background-color: #f0f3f9;
      .td {
        float: none;
        width: 100%;
        font-size: 2.4rem;
        letter-spacing: -1px;
        line-height: 4rem;
        padding-bottom: 8rem;
      }
    }
    .th {
      float: none;
      width: 100%;
      padding-bottom: 4rem;
      font-size: 3.4rem;
      font-weight: bold;
      letter-spacing: -2px;
      line-height: 4.4rem;
    }
    .history-list {
      padding: 16.5rem 4.8rem 6rem;
      .history-ul {
        float: none;
        width: 100%;
        li {
          .year {
            float: none;
            width: 100%;
            font-family: "Poppins";
            font-size: 3rem;
            font-weight: 700;
            color: #00a6bf;
            padding: 3rem 0 4rem;
          }
          > ul {
            float: none;
            width: 100%;
            border-top: 1px solid #e3e8f3;
            margin-bottom: 6rem;
            li {
              padding: 2.3rem 3rem;
              border-bottom: 1px solid #e3e8f3;
              .no {
                float: left;
                width: 6.5rem;
                font-size: 2.4rem;
                font-weight: 500;
                line-height: 3.8rem;
              }
              .txt {
                float: left;
                width: calc(100% - 6.5rem);
                font-size: 2.4rem;
                line-height: 3.8rem;
                letter-spacing: -1px;
              }
            }
          }
        }
      }
    }
  }
}

/* 기관소개 - 조직안내 */
.organization {
  padding: 100px 0;
  background-color: #f0f3f9;

  .organization-chart {
    max-width: 1197px;
    width: 90%;
    height: 897px;
    background: url('/assets/images/intro/organization.png') no-repeat 50% 0 / contain;
    margin: 0 auto;
  }
}

@media screen and (max-width:1000px) {
  .organization {
    padding: 170px 0 100px;
    .organization-chart {
      width: 90%;
      height: 68vw;
    }
  }
}

@media screen and (max-width:750px) {
  .organization {
    padding: 17rem 0;
    background-color: #f0f3f9;

    .organization-chart {
      width: 65.4rem;
      height: 50rem;
      background: url('/assets/images/intro/organization.png') no-repeat 50% 0 / contain;
      margin: 0 auto;
    }
  }
}

/* 기관소개 > 주요업무 */
.business {
  .bg {
    height: 250px;
    background-color: #f0f3f9;
  }
  .business-img {
    max-width: 1200px;
    width: 100%;
    height: 250px;
    background: url('/assets/images/intro/business01.png') no-repeat 50%;
    margin: -130px auto 0;
  }
  .business-list {
    padding-bottom: 110px;
    margin: 40px auto 0;
    > li {
      display: flex;
      align-items: center;
      float: left;
      width: calc(50% - 20px);
      height: 130px;
      padding: 0 38px;
      border: 2px solid #e2e8f3;
      border-radius: 0 25px 25px 25px;
      margin-bottom: 40px;

      &:nth-child(2n) {
        margin-left: 40px;
      }
      p:nth-child(1) {
        float: left;
        font-family: 'Poppins';
        font-size: 32px;
        font-weight: 700;
        color: #00a6bf;
        padding-right: 40px;
      }
      p:nth-child(2) {
        font-size: 17px;
        font-weight: 500;
        letter-spacing: -1px;
        line-height: 24px;
      }
    }
  }
}

@media screen and (max-width:1000px) {
  .business {
    .business-list {
      margin: 40px auto 110px;
      > li {
        float: none;
        width: 100%;
        &:nth-child(2n) {
          margin-left: 0;
        }
      }
    }
  }
}

@media screen and (max-width:750px) {
  .business {
    .bg {
      height: 27rem;
      background-color: #f0f3f9;
    }
    .business-img {
      width: calc(100% - 9.6rem);
      height: 25rem;
      background: url('/assets/images/intro/business01.png') no-repeat 50% / contain;
      margin: -17rem auto 0;
    }
    .business-list {
      margin: 4rem auto 18rem;
      > li {
        display: flex;
        align-items: center;
        float: none;
        width: 100%;
        height: 13rem;
        padding: 0 3rem;
        border: 2px solid #e2e8f3;
        border-radius: 0 2.5rem 2.5rem 2.5rem;
        margin-bottom: 2rem;

        &:nth-child(2n) {
          margin-left: 0;
        }
        p:nth-child(1) {
          float: left;
          font-family: 'Poppins';
          font-size: 3.4rem;
          font-weight: 700;
          color: #00a6bf;
          padding-right: 3rem;
        }
        p:nth-child(2) {
          font-size: 2.4rem;
          font-weight: 500;
          letter-spacing: -1px;
          line-height: 3.4rem;
        }
        .pc-only {
          display: none;
        }
      }
    }
  }
}

.information {
  .information-wrap {
    padding: 100px 0 30px;
    .mobile-only {display: none;}
  }
  .section {
    padding: 0 0 100px;
    .section-title {
      font-size: 20px;
      font-weight: bold;
      letter-spacing: -1px;
      padding-bottom: 25px;
    }
  }
  .list-table {
    .col1 {width: 25%;}
    .col2 {width: 45%;}
    .col3 {width: 30%;}
  }
}

@media screen and (max-width:1300px) {
  .information {
    .information-wrap {
      padding: 170px 40px 30px;
    }
  }
}

@media screen and (max-width:750px) {
  .information {
    .information-wrap {
      padding: 17rem 4.8rem 5rem;
      .pc-only {display: none;}
    }
    .section {
      padding: 0 0 10rem;
      .section-title {
        font-size: 3rem;
        font-weight: bold;
        letter-spacing: -2px;
        line-height: 3.7rem;
        padding-bottom: 3rem;
      }
      .list-table {
        .col1 {width: 0%;}
        .col2 {width: 65%;}
        .col3 {width: 35%;}
        tr {
          &:nth-child(1) > td:nth-child(1) {
            display: hidden;
          }
          .pc-td {
            padding: 0;
            border: none;
          }
        }
        .HBtn {
          border: none;
        }
      }
    }
  }
}

.customer {
  .customer-wrap {
    padding: 100px 0 30px;
    .mobile-only {display: none;}
  }
  .guide-box {
    height: 202px;
    padding: 40px 60px 40px 190px;
    margin-bottom: 30px;
    background: #f6f6f6 url('/assets/images/customer/icon_guide1.png') no-repeat 60px 50%;
    border-radius: 0 30px 30px 30px;

    .txt-area {
      float: left;
      padding-top: 35px;
      .title {
        font-size: 17px;
        font-weight: bold;
        color: #004896;
        letter-spacing: -1px;
        padding-bottom: 15px;
      }
      .txt {
        font-size: 14px;
        font-weight: 500;
        color: #777;
        letter-spacing: -1px;
        line-height: 24px;
      }
    }

    .btn-area {
      float: right;
      width: 250px;
      .btn1 {
        font-size: 14px;
        background: #fff;
        border: 1px solid #d3d5db;
        margin-bottom: 10px;
        &.btn2 {
          margin-top: 33px;
        }
      }

    }
  }
  .section {
    padding: 0 0 100px;
    .section-title {
      font-size: 20px;
      font-weight: bold;
      letter-spacing: -1px;
      padding-bottom: 30px;
    }
    .list-table {
      margin-bottom: 20px;
      td {
        font-size: 17px;
        text-align: left;
        padding: 15px 40px;
        .iconK {
          display: inline-block;
          width: 20px;
          height: 20px;
          background: url('/assets/images/customer/icon_k.png') no-repeat 50%;
          vertical-align: bottom;
        }
        .info {
          font-size: 14px;
          color: #777;
        }
      }
    }

    .board-header {
      margin-bottom: 10px;
      .cnt {
        float: left;
        font-size: 14px;
        font-weight: 500;
        color: #777;
        letter-spacing: -1px;
        line-height: 37px;
      }
      .selectBox {
        width: 120px;
        height: 35px;
        .MuiInputBase-root .MuiInputBase-input {
          height: 35px;
          line-height: 1;
          padding: 0 10px;
          border-radius: 0;
          border: 1px solid #ddd;
          border-right: none;
        }
        .MuiInput-underline:before, .MuiInput-underline:hover:before {
          border-bottom: none;
        }
      }
      .input {
        width: 190px;
        height: 35px;
        margin-right: 5px;
        .MuiOutlinedInput-input {
          height: 37px;
          padding: 0 10px;
          border-left: none;
        }
        .MuiOutlinedInput-root {
          border-radius: 0;
        }
      }
      .HBtn {
        width: 75px;
        height: 37px;
        border-radius: 0;
      }
    }
    .list-table.board-table {
      border-top: 1px solid #d3d6dc;
      th, td {
        height: 57px;
        text-align: center;
        padding: 0;
        border-left: none;
        border-right: none;
      }
    }
    .pagination {
      margin: 40px auto 150px;

      .MuiPagination-ul {
        justify-content: center;

        .MuiPaginationItem-root {
          width: 35px;
          min-width: 35px;
          height: 35px;
          border: 1px solid #d5d5d5;
          padding: 0;
          margin: 0 5px 0 0;
          border-radius: 50%;
          color: #333;

          svg {
            color: #000;
          }

          &.Mui-selected {
            background: #e3eefa;
            border-color: #8cbae9;
          }
        }
        .MuiPaginationItem-ellipsis {
          border: none;
        }
      }
    }
    .list-table.input-table {
      border-top: 1px solid #d3d6dc;
      th, td {
        height: 57px;
        text-align: center;
        padding: none;
        border-left: none;
        border-right: none;
      }
      td {
        text-align: left;
        padding: 10px 30px;
        .inputbox {
          width: 315px;
          height: 37px;
          max-width: 100%;
          input {
            height: 37px;
            padding: 0 10px;
          }
        }
        .txt {
          font-size: 14px;
          color: #777;
          padding: 10px 0;
        }
        .HBtn {
          margin-bottom: 10px;
        }
      }
    }
    .input-btn {
      text-align: center;
      margin: 20px 0;
      .HBtn {
        width: 250px;
        height: 56px;
      }
    }
  }
}

@media screen and (max-width:1300px) {
  .customer {
    .customer-wrap {
      padding:  170px 50px 30px;
    }
    .guide-box {
      height: auto;
      .txt-area {
        float: none;
        padding-top: 0;
      }
      .btn-area {
        float: none;
        margin-top: 20px;
      }
    }
  }
}

@media screen and (max-width:750px) {
  .customer {
    .customer-wrap {
      padding: 17rem 4.8rem 5rem;
      .pc-only {display: none;}
      .mobile-only {display: block;}
    }
    .guide-box {
      height: auto;
      padding: 4rem;
      margin-bottom: 6rem;
      background: #f6f6f6;
      border-radius: 0 3rem 3rem 3rem;

      .txt-area {
        float: none;
        padding-top: 0;
        .title {
          font-size: 2.8rem;
          font-weight: bold;
          color: #004896;
          letter-spacing: -1px;
          line-height: 4rem;
          padding-bottom: 3rem;
        }
        .txt {
          font-size: 2.4rem;
          font-weight: 500;
          color: #777;
          letter-spacing: -1px;
          line-height: 3.6rem;
        }
      }
      .btn-area {
        float: none;
        width: 100%;
        margin: 0;
        .btn1 {
          font-size: 2.8rem;
          background: #fff;
          border: 1px solid #d3d5db;
          margin: 2rem 0;
          &.btn2 {
            margin-top: 33px;
          }
        }
      }
    }
    .section {
      padding: 0 0 10rem;
      .section-title {
        font-size: 3rem;
        font-weight: bold;
        letter-spacing: -2px;
        line-height: 3.7rem;
        padding-bottom: 3rem;
      }
      .list-table {
        margin-bottom: 2rem;
        &.mobile-only {display: table;}
        td {
          font-size: 2.4rem;
          text-align: left;
          padding: 3rem;
          .iconK {
            display: inline-block;
            width: 4rem;
            height: 4rem;
            background: url('/assets/images/customer/icon_k.png') no-repeat 50% / 2rem auto;
            vertical-align: inherit;
          }
          .info {
            font-size: 2.4rem;
            color: #777;
          }
        }
      }

      .board-header {
        margin-bottom: 2.5rem;
        .cnt {
          float: none;
          font-size: 2.4rem;
          font-weight: 500;
          color: #777;
          letter-spacing: -1px;
          line-height: 1;
          margin-bottom: 2rem;
        }
        .selectBox {
          font-size: 2.4rem;
          width: 22rem;
          height: 6.6rem;
          margin: 0 1rem 2rem 0;
          .MuiInputBase-root .MuiInputBase-input {
            font-size: 2.4rem;
            height: 6.6rem;
            padding: 0 2rem;
            border-radius: 0;
            border-right: 1px solid #d6d6d6;
          }
        }
        .input {
          font-size: 2.4rem;
          width: calc(100% - 23rem);
          height: 7rem;
          margin: 0 0 1rem;
          .MuiOutlinedInput-input {
            font-size: 2.4rem;
            height: 7rem;
            padding: 0 2rem;
            border-left: none;
          }
          .MuiOutlinedInput-root {
            border-radius: 0;
          }
        }
        .HBtn {
          font-size: 2.8rem;
          width: calc(100vw - 9.6rem);
          height: 7rem;
        }
      }
      .list-table.board-table {
        border-top: 1px solid #d3d6dc;
        th, td {
          font-size: 2.4rem;
          height: auto;
          padding: 2.6rem 0.5rem;
          text-align: center;
          padding: none;
          border-left: none;
          border-right: none;
          .HBtn {
            font-size: 2.2rem;
            height: auto;
            padding: 0;
            border: none;
          }
        }
        td {
          font-size: 2.2rem;
        }
      }
      .pagination {
        margin: 4rem auto;

        .MuiPagination-ul {
          justify-content: center;

          .MuiPaginationItem-root {
            font-size: 2rem;
            width: 5rem;
            min-width: 5rem;
            height: 5rem;
            border: 1px solid #d5d5d5;
            padding: 0;
            margin: 0 0.3rem;
            border-radius: 50%;
            color: #333;

            svg {
              color: #000;
            }

            &.Mui-selected {
              background: #e3eefa;
              border-color: #8cbae9;
            }
          }
          .MuiPaginationItem-ellipsis {
            border: none;
          }
        }
      }
      .list-table.input-table {
        border-top: 1px solid #d3d6dc;
        th, td {
          height: 57px;
          text-align: center;
          padding: none;
          border-left: none;
          border-right: none;
        }
        td {
          text-align: left;
          padding: 1rem 2rem;
          .inputbox {
            width: 31.5rem;
            height: 8rem;
            input {
              height: 8rem;
              padding: 0 2rem;
            }
          }
          .txt {
            font-size: 2.4rem;
            color: #777;
            padding: 2rem 0;
          }
          .HBtn {
            width: 16.5rem;
            height: 7rem;
            margin-bottom: 2rem;
          }
        }
      }
      .input-btn {
        text-align: center;
        margin: 4rem 0;
        .HBtn {
          width: 100%;
          height: 8rem;
        }
      }
    }
  }
}

.iconK {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url('/assets/images/customer/icon_k.png') no-repeat 50%;
  vertical-align: bottom;
}

.MuiButton-root {
  display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0;
    border: 0;
    margin: 0;
    border-radius: 0;
    padding: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-text-decoration: none;
    text-decoration: none;
    color: inherit;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    padding: 5px 15px;
    border-radius: 4px;
    -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border: 1px solid rgba(25, 118, 210, 0.5);
    color: #1976d2;
}