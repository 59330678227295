.user-login {
  position: fixed;
  top: 0px;
  display:flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background: url('/assets/images/login_background.png') 50% 50%;

  .login-wrap {
    width: 420px;
    height: 550px;
    padding: 40px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 0 10px;
  }

  .login-logo {
    background: url('/assets/images/login_logo.png') no-repeat 50%;
    margin: 40px auto 50px 0px;
    height: 50px;
  }
  .inputbox {
    .MuiFormControl-root {
      font-size: 16px;
      flex-direction: initial;
      margin-bottom: 10px;

      .MuiOutlinedInput-root {
        width: 340px;
        font-size: 16px;
        color: #333;

        input {
          height: 48px;
          padding: 0 20px;
        }
      }
      .MuiOutlinedInput-root fieldset {
        border: 1px solid #9aabd7;
      }
      .MuiOutlinedInput-root:hover fieldset {
        border: 2px solid #415282;
      }
      .MuiOutlinedInput-root.Mui-focused fieldset {
        border: 2px solid #415282;
      }
    }
  }

  .login-btn {
    font-size: 16px;
    color: #fff;
    width: 340px;
    border-radius: 3px !important;
    background-color: #415282 !important;
    margin: 20px 0;
    height: 50px;
  }

  /* CheckBox */
  .Checkbox.MuiFormControlLabel-root {
    height: 28px;

    .MuiSvgIcon-root {
      width: 21px;
      height: 21px;
      background: #dce1e8;
      border: 1px solid #d6d6d6;
    }
    .MuiCheckbox-root {
      color: #000;
    }
    .MuiTypography-body1 {
      font-size: 16px;
    }
  }
}

.footer-txt {
  font-size: 11px;
  color: #606061;
  text-align: center;
  margin-top:40px;
}