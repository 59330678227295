body {
  background: #ddd;
}
.btn-area {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  padding: 10px;
  margin: 0 auto;
  background: #fff;
  border: 1px solid #ddd;
  text-align: right;
  z-index: 9;
}

.userStrenthCheck-print {
  position: relative;
  width: 20.9cm;
  height: 29.5cm;
  padding: 50px 40px 0;
  margin: 0 auto 10px;
  background: #fff;

  .print-header {
    .title {
      font-size: 32px;
      font-weight:500;
      text-align: center;
      margin-bottom: 40px;
    }
    .guide-txt {
      font-size: 16px;
      line-height: 1.3;
      padding-left: 70px;
      text-indent: 10px;
      margin-bottom: 25px;
    }
  }

  .report-tbl {
    table-layout: fixed;
    margin-bottom: 5px;
    th {
      font-size: 12px;
      font-weight: 500;
      height: 25px;
      border: 1px solid #aaa;
      text-align: center;
    }
    td {
      font-size: 12px;
      height: 21px;
      padding: 0 5px;
      border: 1px solid #aaa;
      text-align: left;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      .sign {
        float: right;
        padding-right: 15px;
      }
      &.tc {
        text-align: center;
      }
    }
  }

  .print-footer {
    position: absolute;
    left: 40px;
    bottom: 40px;
    width: calc(100% - 80px);
    .footer1 {
      .sign {
        float: left;
        width: 100px;
        height: 75px;
        border: 1px solid #aaa;
        .th {
          font-size: 13px;
          font-weight: 500;
          line-height: 23px;
          height: 25px;
          text-align: center;
          border-bottom: 1px solid #222;
        }
      }
      .info {
        float: right;
        width: 250px;
        th, td {
          font-size: 13px;
          font-weight: 500;
          text-align: left;
          height: 25px;
        }
      }
    }
    .footer2 {
      padding-top: 30px;
      .com {
        float: left;
        font-size: 12px;
      }
      .document-no {
        float: right;
        font-size: 12px;
      }
    }
  }
}

@page {
  size: A4 portrait;
  margin: 0;
}

@media print {
  body {
    padding: 0;
  }
  .btn-area {
    display:none;
  }
  .report {
    margin: 0;
    width: 100%;
    page-break-after: always;
  }
}

