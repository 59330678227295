body {
  background: #ddd;
}
.btn-area {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  padding: 10px;
  margin: 0 auto;
  background: #fff;
  border: 1px solid #ddd;
  text-align: right;
  z-index: 9;
}

.report {
  position: relative;
  width: 29.5cm;
  height: 20.9cm;
  padding: 30px 40px 0;
  margin: 0 auto 10px;
  background: #fff;
  .title {
    font-size: 24px;
    text-align: center;
    text-decoration: underline;
    margin-bottom: 30px;
  }
  .date {
    font-size: 14px;
    text-align: left;
    margin-bottom: 10px;
  }
  .report-tbl {
    table-layout: fixed;
    th {
      font-size: 12px;
      height: 25px;
      border: 1px solid #aaa;
      border-bottom: none;
      text-align: center;
    }
    td {
      font-size: 10px;
      height: 21px;
      border: 1px solid #aaa;
      text-align: center;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      &.tl {
        text-align: left;
        padding-left: 5px;
      }
      &.tr {
        text-align: right;
        padding-right: 5px;
      }
      &.red {
        color: red;
      }
      &.small {
        font-size: 9px;
      }
    }
    thead.small,
    tbody.small {
      th, td {
        font-size: 9px;
      }
    }
  }

  .print-footer {
    position: absolute;
    left: 40px;
    bottom: 40px;
    width: calc(100% - 80px);
    .guide-txt {
      font-size: 10px;
      line-height: 1.3;
      padding: 10px 0;
      .space {
        display: inline-block;
        width: 18px;
      }
    }
    .footer {
      padding-top: 5px;
      border-top: 2px solid #222;
      .com {
        float: left;
        font-size: 10px;
      }
      .footer-right {
        float: right;
        .document-no {
          font-size: 10px;
          padding-right: 30px;
        }
        .page {
          font-size: 10px;
        }
      }

    }
  }
}

@media print {
  body {
    padding: 0;
  }
  .btn-area {
    display:none;
  }
  .report {
    margin: 0;
    width: 100%;
    page-break-after: always;
  }
}
