.contents-body {
  height: 100%;
  background: #dce1e8;
}
.contents {
  width: 100%;
  height: 100vh;
  padding: 80px 20px 20px 250px;
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.contents::-webkit-scrollbar {
   display: none; /* Chrome, Safari, Opera*/
}

/* page header */
.page-header {
  height: 50px;

  .page-title {
    position: relative;
    float: left;
    font-size: 20px;
    font-weight: 500;
    color: #132235;
    line-height: 45px;

    &:before {
      position: absolute;
      content: '';
      width: 40px;
      height: 3px;
      background-color: #343b3f;
    }
  }
}
/* page contents */
.page-contents {
  min-height: calc(100vh - 150px);
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
}
.contents-header {
  .Btn.sm .MuiSvgIcon-root {
    font-size: 20px;
    color: #737d8a;
  }
  .right-area {
    float: right;
    .Btn {float: left;}
  }
  .label {
    font-size: 12px;
    font-weight: 500;
    color: #333;
    line-height: 28px;
  }
}


/* Header */
.admin-header {
  position: fixed;
  width: 100%;
  height: 60px;
  top: 0px;
  background: #fff;
  border-bottom: 1px solid #c7d2d5;
  z-index: 999;

  .logo {
    float: left;
    display:flex;
    justify-content: center;
    align-items: center;
    width: 230px;
    height: 100%;

    img {
      height: 26px;
    }
  }
  .menu-btn {
    color: #fff;
    height: 59px;
  }

  .util-area {
    float: right;

    .top-icon {
      display: inline-block;
      width: 59px;

      .right-icon {
        font-size: 25px;
        color: #606060;
        height: 60px;
        line-height: 60px;
      }
    }
    .login-info {
      display: inline-block;
      width: 60px;
      height: 60px;
      padding-top: 10px;
      vertical-align: bottom;
      cursor: pointer;
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
    }

  }
}

.admin-header-back {
  background-color: #102643;
  .admin-header-path {
    ul {
      li {
        color: #fff;
      }
    }
  }

  .util-area {
    li {
      a {
        span {
          color: #fff !important;
          &::before {
            color: #fff !important;
          }
        }
      }
    }
  }
}

/* SNB */
.admin-snb {
  /*
  position: fixed;
  left: 0;
  top: 60px;
  */
  width: 230px;
  height: calc(100vh - 75px);
  background-color: #e9edf5;
  z-index: 9;
  border-right: solid 1px #d1d8e2;

  .admin-mainmenu {
    margin-top: 5px !important;
  }

  .admin-snbmenu {
    height: 100%;
    padding: 0px;
    overflow-y: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
     display: none; /* Chrome, Safari, Opera*/
    }

    ul {
      background-color: #fff;
      padding: 0px;
      margin: 0px 5px 0px 5px;

      .menu {
        height: 40px;
        cursor: pointer;
        .depth1:hover {
          color: #fff;
          background-color: #00a6bf;

          .menu-icon {color: #fff;}
          .title {color: #fff;}
          .MuiSvgIcon-root {fill: #fff;}
        }
        .title {
          float: left;
          font-size: 14px;
          font-weight: 500;
          color: #3d3d59;
          line-height: 40px;
        }
        .icon1 {
          float: left;
          width: 38px;
          height: 40px;
          line-height: 40px;
          text-align: center;
        }
        .icon2 {
          float: right;
          width: 18px;
          height: 18px;
          padding: 11px 0 0;
          margin-right: 10px;
          .MuiSvgIcon-root {
            font-size: 18px;
            color: #737d8a;
          }
        }
        &:hover {
          .title {color: #383838;}
        }
        &.open {
          height: auto;
          padding-bottom: 5px;
          background-color: #004896 !important;

          .depth1:hover {
            background-color: #434773 !important;
          }

          .title {color: #fff;}
          .icon1 {
            .menu-icon {
              color: #fff;
            }
          }
          .icon2 {
            transform: rotate(90deg);
            transition: .2s;
            padding: 0 0 0 11px;
            color: #fff;

            .MuiSvgIcon-root {
              fill: #fff;
            }
          }
          .submenu {
            height: auto;
            transition: height .5s ease;
            border-top: solid 1px #ceccd8;
            border-left: solid 1px #ceccd8;

            li.on {
              background-color: rgb(142, 162, 228);
            }

            li:hover {
              background-color: rgb(142, 162, 228);
            }
          }
        }
      }
      .submenu {
        height: 0;
        overflow: hidden;
        transition: height .5s ease;
        background-color: #dce1e8;

        li {
          .subtitle {
            display: block;
            font-size: 13px;
            font-weight: 500;
            color: #333;
            padding: 10px 15px 10px 10px;

            &:active,
            &:hover {
              text-decoration: none;
            }

            .icon {
              float: left;
              width: 14px;
              height: 14px;
              padding-right: 20px;
              .MuiSvgIcon-root {
                font-size: 10px;
                color: #737d8a;
              }
            }
          }
        }
      }
    }
  }
}

.contents.reduce {
  padding: 80px 20px 20px 90px;
}
.contents.reduce .snb {
  width: 70px;

  .admin-snbmenu {
    height: 100%;
    padding: 20px 10px;

    .menu {
      height: 40px;
      cursor: pointer;
      .depth1:hover {background-color: #ccc;}
      .title {
        display: none;
        margin-left: 10px;
        position: fixed;
        left: 74px;
        width: 180px;
        z-index: 99999;
        background-color: #f0f2f5;
        padding: 0px 0px 0px 30px;
        margin: 0px;
        box-shadow: 4px 4px 5px #737d8a;
        opacity: 100;
        transition: opacity 0.3s ease;
      }
      .icon1 {
        width: 30px;
      }
      &.open {
        .title {
          display: block;
        }
      }
    }
    .submenu {
      position: fixed;
      left: 74px;
      width: 180px;
      z-index: 99999;
      background-color: #f0f2f5;
      padding: 0;
      margin: 0;
      display: inline-block;
      box-shadow: 4px 4px 5px #737d8a;
      opacity: 100;
      transition: opacity 0.3s ease;
    }
  }
}

/* 업체용 detail */
.user-detail {
  border: 1px solid #ddd;
  margin: 10px auto;

  .detail-header {
    border-bottom: 1px solid #ddd;

    .detail-title {
      font-size: 18px;
      line-height: 40px;
      text-align: center;
      background: #f2f2f2;
      border-bottom: 1px solid #ddd;
    }
    .header2 {
      text-align: center;
      height: 40px;
      .MuiTypography-body1 {
        font-size: 14px;
      }
    }

  }
}

.alarm-count {
  position: absolute;
  display: inline-block;
  border-radius: 9px;
  width: 18px;
  height: 18px;
  top: 29px;
  margin-left: -12px;
  font-size: 8px;
  background-color: #cf0000;
  color: #fff;
  line-height: 14px;
  border: solid 1px #7b0000;
  text-align: center;
  font-weight: bold;
}