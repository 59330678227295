/* header */
.header {
  position: fixed;
  width: 100%;
  height: 90px;
  background-color: #fff;
  border-bottom: 1px solid #eee;
  z-index: 9;
  top: 0px;

  .header-inner {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 0 50px;
    margin: 0 auto;
    max-width: 1900px;
    width: 100%;
  }

  .logo {
    display: flex;
    align-items: center;
    height: 90px;
    float: left;
    margin-right: 20px;
    .mobile-only {display: none;}
  }
  .gnb {
    float: left;
    .menu {
      > li {
        position: relative;
        float: left;
        padding: 0 75px;
        .depth1 {
          display: block;
          font-size: 18px;
          font-weight: 500;
          line-height: 82px;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
          &:hover {
            text-decoration: none;
          }
        }
        &:hover {
          .depth1 {
            color: #004896;
            border-bottom: 4px solid #004896;
          }
          .submenu-wrap {
            display: block;
          }
        }
        .submenu-btn {
          display: none;
        }
      }
      .submenu-wrap {
        display: none;
        position: fixed;
        top: 90px;
        left: 0;
        width: 100%;
        height: 70px;
        background-color: #fafafa;
        .submenu {
          display: block;
          width: max-content;
          margin: 0 auto;
          background: red;
          > li {
            float: left;
            margin: 0 30px;
            .depth2 {
              font-size: 18px;
              letter-spacing: -1px;
              line-height: 70px;
              &:hover {
                color: #004896;
                font-weight: 500;
                text-decoration: none;
              }
            }
          }
        }
      }
    }
    .totalmenu-bg {display: none;}
  }
  .util-menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-width: 297px;
    height: 90px;
    .user-menu {
      float: left;
      > li {
        float: left;
        padding-right: 40px;
        .usermenu {
          font-size: 18px;
          font-weight: 500;
          line-height: 90px;
        }
      }
    }
    .tot-menu {
      float: left;
      width: 24px;
      height: 19px;
      background: url('/assets/images/header/icon_totmenu.png');
      border-radius: 0;
    }
  }
  .close-menu {
    display: none;
    position: fixed;
    top: 67px;
    right: 80px;
    width: 30px;
    height: 30px;
    background: url('/assets/images/header/icon_close.png') no-repeat;
  }
  .mobile-user {
    display: none;
  }
}

/* 전체메뉴 */
.header.open {
  position: fixed;
  width: 100%;
  height: 100vh;
  padding: 60px 80px;
  border: none;
  background-color: #fff;
  z-index: 99;

  .header-inner {
    display: block;
    padding: 0;
  }
  .logo {
    display: block;
    height: 46px;
    float: none;
    img {height: 46px;}
  }
  .gnb {
    float: none;
    .menu {
      width: max-content;
      margin: 84px auto 0;
      > li {
        position: relative;
        float: left;
        margin: 0 38px;
        padding: 0;
        .depth1 {
          display: block;
          font-size: 30px;
          font-weight: 500;
          color: #fff;
          line-height: 80px;
          width: 270px;
          height: 80px;
          background-color: #004896;
          border-radius: 0 30px 30px 30px;
          text-align: center;
          border: none;
          &:hover {
            text-decoration: none;
          }
        }
        &:nth-child(2n) .depth1 {
          background: #00a6bf;
        }
        .submenu-btn {
          display: none;
        }
      }
      .submenu-wrap {
        display: block;
        position: initial;
        top: 70px;
        left: -30px;
        padding-top: 60px;
        background: transparent;
        .arrow {
          display: none;
        }
        .submenu {
          width: auto;
          padding: 0;
          background-color: transparent;
          filter: none;
          border: none;
          border-radius: 0;
          > li {
            float: none;
            margin: 0 0 48px;
            .depth2 {
              font-size: 24px;
              font-weight: 500;
              letter-spacing: -1px;
              line-height: 1;
              &:hover {
                color: #004896;
              }
            }
          }
        }
      }
    }
    .totalmenu-bg {display: block;}
  }
  .util-menu {
    display: none;
  }
  .close-menu {
    display: block;
  }
}


@media screen and (max-width:1640px) {
  .header {
    .gnb {
      display: none;
    }
  }

  /* 모바일메뉴 */
  .header.open {
    position: fixed;
    right: 0;
    width: 100%;
    height: 100vh;
    padding: 0;
    background-color: #fff;
    border: none;
    z-index: 99;
    .inner {
      max-width: 100%;
      padding: 0;
    }
    .logo {
      display: none;
    }
    .gnb {
      float: none;
      display: block;
      .menu {
        width: 100%;
        margin: 84px auto 0;
        > li {
          position: relative;
          float: none;
          margin: 0;
          border-bottom: 1px solid #eee;
          .depth1 {
            display: block;
            font-size: 17px;
            font-weight: 500;
            color: #222;
            line-height: 60px;
            width: 100%;
            height: 60px;
            padding: 0 48px;
            background-color: #fff;
            border-radius: 0;
            text-align: left;
            &:hover {
              text-decoration: none;
            }
          }
          &:nth-child(2n) .depth1 {
            background: #fff;
          }
          &:hover {
            .submenu-wrap {
              display: none;
            }
          }
          &.open {
            .submenu-wrap {
              display: block;
              height: auto;
            }
            .submenu-btn {
              transform: rotate(180deg);
            }
          }
          .submenu-btn {
            display: block;
            position: absolute;
            top: 18px;
            right: 48px;
            width: 30px;
            height: 30px;
            overflow: hidden;
            text-indent: -99px;
            border: 0;
            background: url('/assets/images/header/icon_submenu.png') no-repeat 50% / 20px auto;
            transition: transform .1s;
          }
        }
        .submenu-wrap {
          display: none;
          position: initial;
          top: 70px;
          left: -30px;
          padding: 20px 0;
          background-color: #f6f7f8;
          .arrow {
            display: none;
          }
          .submenu {
            width: 100%;
            padding: 0 48px;
            background-color: transparent;
            filter: none;
            border: none;
            border-radius: 0;

            > li {
              margin-bottom: 0;
              .depth2 {
                font-size: 14px;
                font-weight: 500;
                letter-spacing: -1px;
                line-height: 60px;
                &:hover {
                  color: #004896;
                }
              }
            }
          }
        }
      }
      .totalmenu-bg {display: none;}
    }
    .util-menu {
      display: none;
    }
    .close-menu {
      display: block;
      top: 30px;
      right: 48px;
    }
  }
}

@media screen and (max-width:750px) {
  .header {
    position: fixed;
    width: 100%;
    height: 11rem;
    background-color: #fff;
    border-bottom: 2px solid #eee;
    z-index: 9;
    .header-inner {
      padding: 0 4.8rem;
    }
    .inner {padding: 0 4.8rem;}
    .logo {
      display: flex;
      align-items: center;
      height: 11rem;
      float: left;
      margin-right: 0;
      .pc-only {display: none;}
      .mobile-only {display: block;}
      img {height: 5.4rem;}
    }
    .gnb {
      display: none;
    }
    .util-menu {
      display: flex;
      align-items: center;
      height: 11rem;
      float: right;
      text-align: right;
      min-width: auto;
      .user-menu {
        display: none;
      }
      .tot-menu {
        float: left;
        width: 4.8rem;
        height: 3.6rem;
        background: url('/assets/images/header/icon_totmenu.png');
        background-size: contain;
      }
    }
    .close-menu {
      display: none;
    }
  }

  /* 모바일메뉴 */
  .header.open {
    position: fixed;
    right: 0;
    width: 100%;
    height: 100vh;
    padding: 0;
    background-color: #fff;
    border: none;
    z-index: 99;
    overflow-y: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
       display: none; /* Chrome, Safari, Opera*/
    }

    .inner {
      max-width: 100%;
      padding: 0;
    }
    .logo {
      display: none;
    }
    .gnb {
      float: none;
      display: block;
      .menu {
        width: 100%;
        margin: 17.2rem auto 0;
        > li {
          position: relative;
          float: none;
          margin: 0;
          border-bottom: 1px solid #eee;
          .depth1 {
            display: block;
            font-size: 4.4rem;
            font-weight: 500;
            color: #222;
            line-height: 12.8rem;
            width: 100%;
            height: 12.8rem;
            padding: 0 8.8rem;
            background-color: #fff;
            border-radius: 0;
            text-align: left;
            &:hover {
              text-decoration: none;
            }
          }
          &:nth-child(2n) .depth1 {
            background: #fff;
          }
          &:hover {
            .submenu-wrap {
              display: none;
            }
          }
          &.open {
            .submenu-wrap {
              display: block;
            }
            .submenu-btn {
              transform: rotate(180deg);
            }
          }
          .submenu-btn {
            display: block;
            position: absolute;
            top: 4.9rem;
            right: 9.7rem;
            width: 3rem;
            height: 3rem;
            overflow: hidden;
            text-indent: -99px;
            border: 0;
            background: url('/assets/images/header/icon_submenu.png') no-repeat 50% / 3rem auto;
            transition: transform .1s;
          }
        }
        .submenu-wrap {
          display: none;
          position: initial;
          top: 70px;
          left: -30px;
          padding: 0;
          background-color: #f6f7f8;
          .arrow {
            display: none;
          }
          .submenu {
            width: 100%;
            padding: 4rem 8.8rem;
            background-color: transparent;
            filter: none;
            border: none;
            border-radius: 0;

            > li {
              margin-bottom: 0;
              .depth2 {
                font-size: 3.4rem;
                font-weight: 500;
                color: #777;
                letter-spacing: -1px;
                line-height: 9.4rem;
                &:hover {
                  color: #222;
                }
              }
            }
          }
        }
      }
      .totalmenu-bg {display: none;}
    }
    .util-menu {
      display: block;
      align-items: center;
      height: auto;
      float: none;
      text-align: left;
      padding: 7.5rem 0 15rem;
      .user-menu {
        display: block;
        float: none;
        width: 100%;
        height: auto;
        top: 0;
        left: 0;
        font-size: 3.2rem;
        font-weight: 500;
        letter-spacing: -2px;
        line-height: 1;
        padding: 0 0 0 8.8rem;

        li {
          float: none;
          height: 8rem;
          .usermenu {
            font-size: 3rem;
            font-weight: 500;
            color: #777;
            line-height: 8rem;
          }
         // &.loginmenu {display: none;}
        }

        &.login-menu {
          position: fixed;

        }
      }
      .tot-menu {
        display: none;
      }
    }
    .close-menu {
      position: fixed;
      display: block;
      top: 4rem;
      right: 5.7rem;
      width: 3rem;
      height: 3rem;
      background: url('/assets/images/header/icon_close.png') no-repeat 50% / contain;
      z-index: 99;
    }
    .mobile-user {
      position: fixed;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 11.2rem;
      background: #fff;
      .login-menu {
        font-size: 3.2rem;
        font-weight: 500;
        line-height: 11.2rem;
        text-decoration: underline;
        padding-left: 4.8rem;
      }
      .login-user {
        font-size: 3.2rem;
        font-weight: 500;
        line-height: 11.2rem;
        padding-left: 4.8rem;
        span {
          color: #004896;
        }
      }
    }
  }
}