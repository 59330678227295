.MuiChip-outlined {
  border-radius: 16px !important;
  font-size: 12px !important;
  background-color: #fff;
}

.MuiChip-contained {
  color: #fff !important;
  border-radius: 16px !important;
  font-size: 12px !important;
}

.MuiChip-contained.MuiChip-containedPrimary {
  background-color: #1976d2 !important;
}

.MuiChip-contained.MuiChip-containedError {
  background-color: #d32f2f !important;
}

.MuiChip-contained.MuiChip-containedSuccess {
  background-color: #2e7d32 !important;
}

.MuiChip-contained.MuiChip-containedSecondary {
  background-color: #9c27b0 !important;
}

.MuiChip-contained.MuiChip-containedDefault {
  background-color: #ccc !important;
}

.MuiChip-outlined.MuiChip-outlinedPrimary {
  color: #1976d2 !important;
  border:solid 1px #1976d2 !important;
}

.MuiChip-outlined.MuiChip-outlinedWarning {
  color: #ed6c02 !important;
  border:solid 1px #d76100 !important;
}

.MuiChip-outlined.MuiChip-outlinedError {
  color: #d32f2f !important;
  border:solid 1px #d32f2f !important;
}

.MuiChip-outlined.MuiChip-outlinedSuccess {
  color: #2e7d32 !important;
  border:solid 1px #2e7d32 !important;
}

.MuiChip-outlined.MuiChip-outlinedSecondary {
  color: #9c27b0 !important;
  border:solid 1px #9c27b0 !important;
}

.MuiChip-outlined.MuiChip-outlinedDefault {
  color: #ccc !important;
  border:solid 1px #ccc !important;
}


/* Button */
.MuiButton-root {
  min-width: auto !important;
  height: 28px;
  font-size: 13px !important;
  padding: 5px 10px !important;

  &.basic {
    font-size: 14px;
    height: 35px;
    padding: 0 15px;
  }
  &.lg {
    font-size: 17px !important;
    font-weight: 500;
    height: 56px;
  }
  &.blueTxt {
    color: #004896;
  }

  &.whiteTxt {
    color: #fff !important;
  }

  &.bluebg {
    color: #fff;
    background-color: #38589f !important;
    border: 1px solid #38589f;
  }
  &.blackbg {
    color: #fff;
    background-color: #5b646e !important;
    border: 1px solid #5b646e;
  }
}
.HBtn {
  .MuiButton-label {
    color: inherit;
  }
}

.MuiInputBase-input {
  padding: 0px 10px;
}

@media screen and (max-width:750px) {
  /* Button */
  .MuiButton-root {
    &.basic {
      font-size: 2.4rem;
      height: 35px;
      padding: 0 15px;
    }
    &.lg {
      font-size: 2.8rem !important;
      font-weight: 500;
      height: 8rem;
    }
  }
}


.btn-search, .btn-refresh, .btn-excel, .btn-grid-save {
  padding: 0px 0px !important;
  width: 28px;
  height: 28px;

  span {
    font-size: 20px;
  }
}

.gridBtn {
  height: 30px;
  padding: 0px 7px !important;
}

.gridBtn1 {
  height: 23px;
  padding: 0px 7px !important;
}

.white {
  padding: 0px 15px;
  line-height: 36px;

  .MuiButton-label {
    color: #fff !important;
  }

  .MuiChip-label {
    color: #fff !important;
  }

  .MuiChip-labelSmall {
    color: #fff !important;
  }
}

.MuiChip-label {
  line-height: 100%;
}

.MuiFormHelperText-root.Mui-error {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 3%;
  height: calc(100% - 4px);
  margin-top: 2px !important;
  z-index: 99;
}

.error-msg {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 20px;
  height: calc(100% - 2px);
  margin-top: 1px !important;
}

.Mui-error {
  background-color: rgb(255, 229, 229) !important;

  &.selectBox > div > div:first-child {
    background-color: rgb(255, 229, 229) !important;
    border: solid 2px red;
  }
}


/* ToggleButton */
.toggleBtn.MuiToggleButtonGroup-root {
  .MuiToggleButton-root {
    height: 38px !important;
    font-size: 15px;
    font-weight: normal;
    color: #fff;
    padding: 0 15px;
    border: solid 1px #eee !important;
  }
  .MuiToggleButton-root.Mui-selected {
    color: #fff;
    background-color: #22282f !important;

    .MuiToggleButton-label {
      color: #fff;
    }
  }
}

/* Toggleutton1 */
.toggleBtnText1.MuiToggleButtonGroup-root {
  .MuiToggleButton-root {
    height: 26px !important;
    font-size: 12px;
    font-weight: 500;
    color: #000;
    padding: 0 10px;
    background-color: #fff;
  }
  .MuiToggleButton-root.Mui-selected {
    color: #fff;
    background-color: #22282f;
  }
}

/* Selectbox */
.selectBox {
  .MuiInputBase-root {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 12px;
    font-weight: 500;
    color: #333;
    text-transform: initial;

    .MuiInputBase-input {
      height: 28px;
      padding: 0px 25px 0px 15px;
      border: 1px solid #ccc;
      border-radius: 3px;
      box-sizing: border-box;
      font-size: 13px;

      &:focus {
        background-color: #fff;
      }


    }
  }

  .MuiInput-underline:before,
  .MuiInput-underline:hover:before,
  .MuiInput-underline:after,
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: none;
  }
  &.h24 {
    .MuiInputBase-input {
      height: 22px;
    }
  }
  &.w150 {
    .MuiInputBase-input {
      width: 150px;
    }
  }
}

.modal-footer {
  .MuiButtonBase-root {
    padding: 6px 20px;
  }
}

.Checkbox.MuiCheckbox-root {
  width: 16px;
  height: 16px;
  /*color: #fff;*/
  svg {
    font-size: 1em;
  }
}

.Checkbox.MuiCheckbox-root.Mui-checked {
  color: #1e57a1;
}

.CheckboxLabel.MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-size: 12px !important;
  font-weight: 500;
  color: #6a6a6a;
  padding-left: 6px;
}

.Checkbox.MuiCheckbox-root.Mui-checked {
  /*color: #fff;*/
}

.MuiButton-outlined {
  border-color: #bbb !important;
}

.MuiButton-contained {
  box-shadow: none !important;
}

.MuiToggleButtonGroup-grouped {
  min-width: 60px !important;
  border:solid 1px #ccc !important;
  height: 28px;
  line-height: 28px;
  padding: 0px 10px !important;

  &.Mui-selected {
    background-color: #555 !important;
    color: #fff !important;
    border:solid 1px #555 !important;
  }
}

.no-min-width {
  button {
    min-width: auto !important;
  }
}

input:disabled {
  background-color: #eee;
}

/*
.rst__node {
  height: 45px !important;
}
*/

.rst__rowContents {
  cursor: pointer !important;
  min-width: 200px;
}

.rst__rowWrapper {
  padding: 5px 10px 5px 0px !important;
}

.rst__moveHandle, .rst__loadingHandle {
  width: 36px;
  //background: #b0acac url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MiIgaGVpZ2h0PSI0MiI+PGcgc3Ryb2tlPSIjRkZGIiBzdHJva2Utd2lkdGg9IjIuOSIgPjxwYXRoIGQ9Ik0xNCAxNS43aDE0LjQiLz48cGF0aCBkPSJNMTQgMjEuNGgxNC40Ii8+PHBhdGggZD0iTTE0IDI3LjFoMTQuNCIvPjwvZz4KPC9zdmc+') no-repeat center;
  //background: #ebe7e7 url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23000000'%3E%3Cpath d='M0 0h24v24H0V0z' fill='none'/%3E%3Cpath d='M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z'/%3E%3C/svg%3E") no-repeat center;
  background: #ebe7e7 url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23000000'%3E%3Cpath d='M0 0h24v24H0V0z' fill='none'/%3E%3Cpath d='M15 3l2.3 2.3-2.89 2.87 1.42 1.42L18.7 6.7 21 9V3h-6zM3 9l2.3-2.3 2.87 2.89 1.42-1.42L6.7 5.3 9 3H3v6zm6 12l-2.3-2.3 2.89-2.87-1.42-1.42L5.3 17.3 3 15v6h6zm12-6l-2.3 2.3-2.87-2.89-1.42 1.42 2.89 2.87L15 21h6v-6z'/%3E%3C/svg%3E") no-repeat center;
  background-size: 16px;
}

.rst__rowTitle {
  font-weight: normal !important;
  font-size: 14px;
}

.tree-selected {
  .rst__moveHandle {
    background-color: #bec4f2;
  }
}

.input-area-btn {
  line-height: 30px !important;
}

.MuiButton-containedSuccess {
  color: #fff !important;
  background-color: #2e7d32 !important;

  &.Mui-disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
  }
}

.MuiButton-outlinedSuccess {
  color: #2e7d32 !important;
  background-color: #fff !important;

  &.Mui-disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
  }
}

.MuiButton-containedError {
  color: #fff !important;
  background-color: #d32f2f !important;

  &.Mui-disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
  }
}

.MuiButton-outlinedError {
  color: #d32f2f !important;
  background-color: #fff !important;

  &.Mui-disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
  }
}

.icon-btn {
  padding: 5px !important;
}

/* InputEx label font-size */
label.MuiInputLabel-formControl  {
  font-size: 12px;
  line-height: 14px;
}

.search-input {
  label.MuiInputLabel-formControl  {
    font-size: 12px;
    line-height: 12px;
  }
}

/* InputEx Adornment font-size */
.MuiInputAdornment-root {
  p {
    font-size: 11px;
    line-height: 100%;
    padding-top: 1px;
  }
}

.MuiInputBase-adornedEnd {
  padding-right: 10px !important;
}

.MuiInputLabel-root.MuiInputLabel-shrink  {
  -webkit-transform: translate(14px, -5px) scale(0.75) !important;
  -moz-transform: translate(14px, -5px) scale(0.75) !important;
  -ms-transform: translate(14px, -5px) scale(0.75) !important;
  transform: translate(14px, -5px) scale(0.75) !important;
}