body {
  width: 100%;
  line-height: 1;
}
body * {
  font-family: 'Arial', 'Noto Sans KR', sans-serif;
  letter-spacing: -0.5px;
  box-sizing: border-box;
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
button {
  border: none;
  outline: none;
  cursor: pointer;
}
a {
  cursor: pointer;
  text-decoration: none;
  color: #222;
}
a:hover {
  text-decoration: underline;
}
p {
  padding: 0;
  margin: 0;
}
table {
  width: 100%;
  border-collapse:collapse;
}

.clearfix::after {
  content: "";
  display: block;
  clear: both;
}
.bold {font-weight: bold !important;}
.vt {vertical-align: top !important;}
.vs {vertical-align: sub !important;}
.vm {vertical-align: middle !important;}
.fl {float: left;}
.fr {float: right;}
.cp {cursor: pointer;}
.left {text-align: left !important;}
.right {
  text-align: right !important;
  input {
    text-align: right !important;
  }
}

.center {text-align: center !important;}
.justify {text-align: justify !important;}
.justify:after {
  content: "";
  display: inline-block;
  width: 100%;
}
.hidden {display: none !important;}

.w40per {width: 40%;}
.w45per {width: 45%;}
.w50per {width: 50%;}
.w55per {width: 55%;}
.w60per {width: 60%;}

.f9 {
  font-size: 9px !important;
}
.f8 {
  font-size: 8px !important;
}
.f10 {
  font-size: 10px !important;

  * {
    font-size: 10px !important;
  }
}
.f11 {font-size: 11px !important;}
.f12 {font-size: 12px !important;}
.f13 {font-size: 13px !important;}
.f14 {font-size: 14px !important;}

.h100 {display: inline-block; width: 100%; height: 100%;}

.p5 {padding: 5px !important;}
.p10 {padding: 10px !important;}
.p20 {padding: 20px !important;}

.pt0 {padding-top: 0px !important;}
.pt2 {padding-top: 2px !important;}
.pt3 {padding-top: 3px !important;}
.pt5 {padding-top: 5px !important;}
.pt10 {padding-top: 10px !important;}
.pt15 {padding-top: 15px !important;}
.pt20 {padding-top: 20px !important;}

.pl5 {padding-left: 5px !important;}
.pl10 {padding-left: 10px !important;}
.pl50 {padding-left: 50px !important;}

.pr0 {padding-right: 0px !important;}
.pr5 {padding-right: 5px !important;}
.pr10 {padding-right: 10px !important;}
.pr20 {padding-right: 20px !important;}

.pb0 {padding-bottom: 0px !important;}
.pb5 {padding-bottom: 5px !important;}
.pb10 {padding-bottom: 10px !important;}
.pb12 {padding-bottom: 12px !important;}
.pb15 {padding-bottom: 15px !important;}
.pb20 {padding-bottom: 20px !important;}
.pb25 {padding-bottom: 25px !important;}
.pb40 {padding-bottom: 40px !important;}

.m0 {margin: 0px !important;}

.mt0 {margin-top: 0px !important;}
.mt1 {margin-top: 1px !important;}
.mt2 {margin-top: 2px !important;}
.mt3 {margin-top: 3px !important;}
.mt5 {margin-top: 5px !important;}
.mt7 {margin-top: 7px !important;}
.mt10 {margin-top: 10px !important;}
.mt15 {margin-top: 15px !important;}
.mt20 {margin-top: 20px !important;}
.mt30 {margin-top: 30px !important;}
.mt33 {margin-top: 33px !important;}
.mt35 {margin-top: 35px !important;}
.mt40 {margin-top: 40px !important;}
.mt65 {margin-top: 65px !important;}

.mb0 {margin-bottom: 0px !important;}
.mb3 {margin-bottom: 3px !important;}
.mb5 {margin-bottom: 5px !important;}
.mb10 {margin-bottom: 10px !important;}
.mb15 {margin-bottom: 15px !important;}
.mb20 {margin-bottom: 20px !important;}
.mb40 {margin-bottom: 20px !important;}

.ml2 {margin-left: 2px !important;}
.ml3 {margin-left: 3px !important;}
.ml4 {margin-left: 4px !important;}
.ml5 {margin-left: 5px !important;}
.ml6 {margin-left: 6px !important;}
.ml10 {margin-left: 10px !important;}
.ml20 {margin-left: 20px !important;}
.ml40 {margin-left: 40px !important;}

.mr3 {margin-right: 3px !important;}
.mr4 {margin-right: 4px !important;}
.mr5 {margin-right: 5px !important;}
.mr6 {margin-right: 6px !important;}
.mr10 {margin-right: 10px !important;}
.mr20 {margin-right: 20px !important;}
.mr50 {margin-right: 50px !important;}

.cgrey {color: grey !important;}
.cred {color: red !important;}
.cblue {color: blue !important;}
.cgreen {color: green !important;}
.bglightgrey {background-color: #f2f2f2;}

.readonly {background-color: #eee;}

/* MyIcons */
.icons-dashboard {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url('/assets/images/icon.png');
  background-position: calc(30px * 0 - 20px) calc(30px * 0 - 20px);
}
.icons-basic {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url('/assets/images/icon.png');
  background-position: calc(30px * (-1) - 20px) calc(30px * 0 - 20px);
}
.icons-inspection {
  display: inline-block;
  width: 17px;
  height: 16px;
  background: url('/assets/images/icon.png');
  background-position: calc(30px * (-2) - 20px) calc(30px * 0 - 20px);
}
.icons-businessTrip {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url('/assets/images/icon.png');
  background-position: calc(30px * (-3) - 20px) calc(30px * 0 - 20px);
}
.icons-receive {
  display: inline-block;
  width: 14px;
  height: 16px;
  background: url('/assets/images/icon.png');
  background-position: calc(30px * (-4) - 20px) calc(30px * 0 - 20px);
}
.icons-setting {
  display: inline-block;
  width: 15px;
  height: 16px;
  background: url('/assets/images/icon.png');
  background-position: calc(30px * (-5) - 20px) calc(30px * 0 - 20px);
}

/* ag-grid */
.ag-theme-balham .ag-header-row {
  /*height: 36px !important;*/
}
.ag-theme-balham .ag-row {
  /*height: 36px !important;*/
}
.ag-theme-balham .ag-cell, .ag-theme-balham .ag-full-width-row .ag-cell-wrapper.ag-row-group {
  font-size: 12px;
  font-weight: normal;
  color: #333;
  /*line-height: 32px !important;*/
  border-right: solid 1px #eee;
  padding: 0px 5px !important;
}
.ag-body-viewport.ag-layout-normal {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.ag-header-cell, .ag-header-group-cell {
  padding: 0px 5px;
}

.ag-header-cell.header-center {
  .ag-header-cell-label {
    justify-content: center;
  }
}
.ag-header-cell.header-right {
  .ag-header-cell-label {
    justify-content: right;
  }
}

/*
.ag-body-viewport.ag-layout-normal::-webkit-scrollbar {
   display: none;
}
*/
/* modal */
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  z-index:1099;

  .modal-wrap {
    height: auto;
    max-height: 100%;
    transform: translate(0);
    background-color: #fff;
    border-radius: 8px;
    .modal-header {
      height: 60px;
      background-color: #fff;
      border-bottom: 1px solid #e9ecef;
      border-radius: 8px 8px 0 0;
      padding: 0 20px;
      .modal-title {
        float: left;
        font-size: 18px;
        font-weight: 500;
        color: #333;
        line-height: 60px;
      }
      .close-btn {
        float: right;
        width: 24px;
        min-width: 24px;
        height: 24px;
        padding: 0;
        margin-top: 18px;
        font-size: 28px;
        color: #333;
        opacity: 0.7;
        &:hover {
          opacity: 1;
        }
        .MuiSvgIcon-root {
          font-size: 28px;
        }
      }
    }
  }
}

.modal-wrap .modal-body {
  padding: 10px;
}
.modal-wrap .modal-footer {
  padding: 10px 20px;
  border-top: 1px solid #e5e5e5;
  background-color: #fff;
  border-radius: 0 0 8px 8px;
  text-align: right;
}

.modal-wrap1 .modal-body label {
  display: block;
  width: 100%;
  margin-bottom: 10px;
}
.modal-wrap .modal-body label .TextField-input.w100 {
  width: calc(100% - 104px);
}
.modal-wrap .modal-body label .TextField-input.MuiFormControl-root .MuiOutlinedInput-root {
  width: 100%;
}

/* tab */
.Tab-page {
  height: 100%;
  .tab-menu {
    width: 100%;
    height: 35px;
    border-bottom: 1px solid #000;
    li {
      float: left;
      font-size: 14px;
      font-weight: 500;
      color: #949494;
      line-height: 32px;
      text-align: center;
      width: calc(100% / 8);
      height: 35px;
      margin-right: 5px;
      border: 1px solid #e5e5e5;
      border-bottom: 1px solid #000;
      background: #f0f2f5;
      cursor: pointer;

      &.active {
        color: #000;
        border: 1px solid #000;
        background: #fff;
      }
    }

    &.menu2 {
      li {
        width: calc(100% / 2);
      }
    }
    &.menu4 {
      li {
        width: calc(100% / 4);
      }
    }
    &.menu5 {
      li {
        width: calc(100% / 5);
      }
    }
    &.menu7 {
      li {
        width: calc(100% / 7);
      }
    }
  }
  .tab-contents {
    height: calc(100% - 5px);
    // padding: 20px 0 0;
    overflow-y: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    > div {
      height: 100%;
    }
  }
  .tab-contents::-webkit-scrollbar {
     display: none; /* Chrome, Safari, Opera*/
  }
}

/* table */
.table-input {
  margin: 15px 0;
  th {
    font-size: 14px;
    font-weight: 500;
    color: #333;
    text-align: right;
    padding-right: 10px;
    background-color: #f2f2f2;
    border: 1px solid #dee2e6;
  }
  td {
    font-size: 12px;
    font-weight: 500;
    color: #333;
    line-height: 28px;
    padding: 5px;
    border: 1px solid #dee2e6;
  }
  .TextField-input.MuiTextField-root {
    width: 100%;
  }
}

.lh150 {
  line-height: 150% !important;
}
.lh22px {
  line-height: 22px;
}
.lh24px {
  line-height: 24px;
}
.lh28px {
  line-height: 28px;
}
.lh30px {
  line-height: 30px;
}
.lh35px {
  line-height: 35px;
}
.lh38px {
  line-height: 38px;
}
.lh40px {
  line-height: 40px !important;
}
.lh45px {
  line-height: 45px;
}

.search-input {
  .MuiInputBase-root {
    border-radius: 3px !important;
  }

  input {
    height: 28px !important;
    line-height: 28px !important;
    padding: 0px 15px !important;
    font-size: 13px;
  }
}

.search-toggle-btn {
  height: 28px !important;
  line-height: 28px !important;
  padding: 0px !important;
  font-size: 13px !important;
  vertical-align: bottom !important;

  button {
    padding: 0px 5px !important;
  }
}

.search-input-btn {
  input {
    height: 36px !important;
    line-height: 34px !important;
    padding: 0px 10px !important;
    font-size: 14px !important;
  }

  .MuiInputLabel-root {

    font-size: 14px !important;
  }
}

.input-date {
  input {
    height: 40px !important;
    line-height: 34px !important;
    padding: 0px 10px !important;
    font-size: 14px !important;
  }

  .MuiInputLabel-root {

    font-size: 14px !important;
  }
}


/* homepage Modal */
.home-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  .modal-wrap {
    max-width: 90%;
    height: auto;
    max-height: 100%;
    transform: translate(0);
    background-color: #fff;
    border-radius: 10px;
  }
}
.ck-voice-label {
  display: none !important;
}

.ck-editor__editable_inline {
  height: calc(100vh - 340px);
}

.MuiCheckbox-root {
  zoom: 1.5;
}

button.grid {
  padding: 4px 10px !important;
  line-height: 22px;
  height: 22px;
  position: relative;
  top: -3px;
}

.print-page {
  position: relative;
  width: 20.9cm;
  min-height: 29cm;
  padding: 50px 40px;
  margin: 0 auto 10px;
  background: #fff;
}

/* aggrid copy clipboard */
.ag-cell-value {
  -webkit-touch-callout: default;
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  cursor:auto;
}

.cell-bottom-border {
  border-bottom: solid 1px #888;
}

.ellipsis-none {
  width: 100%;
  overflow: hidden;
  text-overflow: none;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-break: break-all;
  height: 22px;
  line-height: 22px;
}

.ellipsis2-none {
  width: 100%;
  overflow: hidden;
  text-overflow: none;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-all;
}

.ellipsis {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
}

.ellipsis2 {
  width: 100%;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

.ellipsis3 {
  width: 100%;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

.ellipsis4 {
  width: 100%;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

.flex {
  display: flex !important;
  justify-content: space-between;
}

.flex-row {
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
}

.ag-cell.red {
  background-color: #ffe7e7
}

#webpack-dev-server-client-overlay {
  display: none;
}

.ag-header-center {
  .ag-header-cell-label {
    .ag-header-cell-text {
      width: 100%;
      text-align: center;
    }
  }
}