.contents {
  padding-top: 90px;
}

.inner {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.list-table {
  th {
    font-size: 17px;
    font-weight: 500;
    letter-spacing: -1px;
    height: 55px;
    background-color: #f0f3f906;
    border: 1px solid #d3d6dc;
  }
  td {
    font-size: 14px;
    font-weight: 500;
    color: #777;
    letter-spacing: -1px;
    line-height: 24px;
    text-align: center;
    padding: 15px 10px;
    border: 1px solid #d3d6dc;
  }
}

@media screen and (max-width:1300px) {
  .inner {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media screen and (max-width:750px) {
  html {font-size: 1.333vw;} /* 10px */

  .contents {
    padding-top: 11rem;
  }
  .inner {
    padding: 0 4.8rem;
  }
  .list-table {
    th {
      font-size: 2.4rem;
      font-weight: 500;
      letter-spacing: -1px;
      min-height: 7.5rem;
      padding: 2.5rem 1rem;
      background-color: #f0f3f9;
      border: 1px solid #d3d6dc;
    }
    td {
      font-size: 2.4rem;
      font-weight: 500;
      color: #777;
      letter-spacing: -1px;
      line-height: 3rem;
      text-align: center;
      padding: 2.5rem 2rem;
      border: 1px solid #d3d6dc;
    }
    &.mobile-only {display: table;}
  }
  .home-contents {
    padding-top: 11rem !important;
    padding-bottom: 1px;
  }
}

.admin-contents {
  padding: 0px 5px 0px 0px !important;
  margin-top: 65px;
  height: calc(100% - 70px) !important;
  min-width: 1920px;
  display: flex;

  .admin-cms-contents {
    background-color: #fff;
    height: calc(100vh - 75px);
    padding: 0px 10px 10px 10px;
    overflow-y: hidden;
    width: calc(1920px - 240px);
    margin-left: 5px;
    min-height: 700px;

    .admin-cms-search-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 10px 0px 0px 0px;

      .MuiOutlinedInput-input {
        height: 28px;
        line-height: 28px;
        padding: 0px 10px;
        font-size: 13px;
      }
    }

    .admin-cms-search-header.sub-header {
      padding: 10px 0px 0px 0px;
      border-bottom: solid 0px #ccc;
      height: auto;
    }

    .admin-cms-search-header.left {
      justify-content: flex-start !important;
    }

    .admin-cms-body {
      margin-top: 10px;
      height: calc(100% - 5px);
      overflow-y: auto;
    }

  }
}

.ag-theme-balham .f15 * {
  font-size: 15px;
}

.ag-header-cell-text {
  color: #333;
  text-align: center;
}


.input-table {
  border-collapse: collapse;

  th {
    background-color: #f9f9f9;
    font-size: 13px;
    color: #333;
    border: solid 1px #eee;
    font-weight: 500;
    text-align: center;
  }

  td {
    font-size: 13px;
    color: #333;
    border: solid 1px #eee;
    padding: 2px;

    .MuiOutlinedInput-input {
      padding: 0px 10px !important;
      min-height: 28px;
      line-height: 28px;
      font-size: 13px;
    }
  }

  td.topNoBorder,
  th.topNoBorder {
    border-top: solid 0px #fff !important;
  }

  .td-border td {
    border-color: #c7c3c3;
    background-color: #f2f2f2;
    text-align: right;
    height: 30px;
    font-weight: 500;
  }

  .td-border1 th {
    border: solid 1px #cecece;
    background-color: #f3f3f3;
    text-align: center;
    height: 31px;
    font-size: 12px;
    font-weight: bold;
  }

  .td-border1 td {
    border: solid 1px #cecece;
    text-align: center;
    height: 31px;
    font-size: 12px;
  }
}

.input-table.table-p5 {
  th, td {
    padding: 5px;
  }
}


.Resizer.vertical {
  width: 8px;
  cursor: col-resize;
  background: #fff url(/assets/images/icon_split.svg) no-repeat 50%;
  opacity: 1 !important;

  &:hover {
    background-color: #fff;
  }
}

.Resizer.horizontal {
  height: 8px;
  cursor: row-resize;
  background: #fff url(/assets/images/icon_split_horizontal.svg) no-repeat 50%;
  opacity: 1 !important;

  &:hover {
    background-color: #fff;
  }
}

.menu-list-add-background {
  padding: 40px;
  border: solid 1px #e9cdcd;
  background-color: #fff2f2;
  text-align: center;
  border-left: solid 7px #960000;
  color: #e80000;
  font-weight: bold;
}

.admin-search-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}

.SplitPane {
  position: static !important;
}

button[role=tab] {
  font-weight: bold;
  font-size: 15px;
}

h4.sub-title {
  float: left;
  margin: 0px 0px 0px 5px;
  line-height: 28px;
  display: block;

  &::before {
    display: inline-block;
    content:"|";
    margin-right: 5px;
  }
}


/* pagination */
.pagination {
  height: 38px;

  .MuiPagination-root {
    float: left;
  }
  .MuiPagination-ul {
    .MuiPaginationItem-root {
      width: 28px;
      min-width: 28px;
      height: 28px;
      line-height: 28px;
      border: 1px solid #c4c4c4;
      padding: 0;
      margin: 0 6px 0 0;
      border-radius: 4px;
      color: #222;
      font-size: 12px;

      svg {
        color: #000;
      }

      &.Mui-selected {
        color: #1e57a1;
        background: #edf2f5;
        border: 1px solid #6a97d2;
      }
    }
    .MuiPaginationItem-ellipsis {
      border: none;
    }
  }
}

.thumb-image {
  margin: 10px 0px;

  li {
    float: left;
    margin-right:10px;
  }

  .thumbnail {
    width: 100px;
    height: 100px;
    cursor: pointer;
    border: solid 5px #fff;
  }

  .thumbnail.on {
    width: 100px;
    height: 100px;
    cursor: pointer;
    border: solid 5px #860000;
  }
}

.preview-image {
  clear: both;
  padding-top: 10px;
  width: 100%;
  display: inline-block;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.not-selected {
  color: #960000;
  border: solid 1px #d98e8e;
  background-color: #ffe0e0;
  padding: 50px;
  text-align: center;
}

.use-info {
  padding: 25px 0px;
  overflow: hidden;
}

.use-info > div {
  height: 35px;
  overflow: hidden;
}

.user-cnt {
  float: right;
  margin-right: 30px;
  font-family: 'Titillium Web', sans-serif;
  font-size : 14px;
  color: #345fdf;
  line-height: 40px;
}

.user-cnt-greycolor {
  color:#5f6062 !important;
  float: right;
  margin-right: 30px;
  font-size : 14px;
  line-height: 40px;
}

.user-cnt-text {
  color:#a9a9a9 !important;
}

.use-icon {
  float: left;
  width: 23px;
  font-size: 5px;
  line-height: 45px;
  margin-left: 30px;
}

.use-icon {
  float: left;
  width: 23px;
  font-size: 5px;
  line-height: 45px;
  margin-left: 30px;
}

.use-title {
  display: inline-block;
  font-family: 'Noto Sans KR', gulim;
  font-size: 14px;
  line-height: 45px;
}

.use-title-small {
  display: none;
}

.progress-bar {
  text-align: left;
  padding-left: 5px;
}

.use-kind-hdd .use-icon {
  color: #539bff;
}

.use-kind-db .use-icon {
  color: #48bf00;
}

.use-kind-email .use-icon {
  color: #ff9600;
}

.use-kind-sms .use-icon {
  color: #dd68ff;
}

.use-kind-hdd .progress-bar {
  background-color: #539bff;
}

.use-kind-db .progress-bar {
  background-color: #48bf00;
}

.use-kind-email .progress-bar {
  background-color: #ff9600;
}

.use-kind-sms .progress-bar {
  background-color: #dd68ff;
}

.menu-icon {
  margin: 0px 10px 0px 18px;
}

.menu-title {
  display: inline-block;
  font-family: 'Noto Sans KR', gulim;
  font-size: 14px;
  line-height: 40px;
}

.menu-arrow {
  float: left;
  margin: 0px;
  line-height: 40px;
}

.admin-header-path {
  float: left;
  line-height: 60px;

  ul {
    li {
      float: left;
      margin-right: 10px;
      font-size: 12px !important;

      span {
        font-size: 12px !important;
      }
    }

    li:last-child {
      font-size: 18px !important;
      font-weight: bold;
    }

    li:last-child::after {
      content: '';
    }

    li::after {
      padding-left: 10px;
      content: '>';
    }
  }
}

.admin-dashboard {
  padding: 0px;
  width: 100%;

  .widget-div {
    display: flex;
    height:100px;
    width:calc(100% + 20px);
    margin:20px 0px 20px 0px;
    justify-content: space-between;

    .widget {
      width:calc(16.6% - 20px);
      margin:0px 20px 0px 0px;
      height: 100px;
      background-color: #fff;
      white-space: nowrap;

      .widget-icon {
        line-height: 90px;

        .material-icons {
          width: 48px;
          text-align: center;
          font-size: 24px;
          color: #fff;
          line-height: 100px;
        }
      }

      .widget-icon1 {
        background-color: #539bff;
      }

      .widget-icon2 {
        background-color: #48bf00;
      }

      .widget-icon3 {
        background-color: #ff9600;
      }

      .widget-icon4 {
        background-color: #dd68ff;
      }

      .widget-icon5 {
        background-color: #737d8a;
      }

      .widget-txt {
        width: calc(100% - 48px);
        height: 50px;
        font-family: 'Noto Sans KR', gulim;
        font-size: 12px;
        color: #383838;
        text-align: left;
        padding: 0px 15px 0px 15px;

        .cnt {
          font-family: 'Titillium Web', gulim;
          font-size: 40px;
          font-weight: 700;
          padding: 0px 2px;
        }

        .date {
          font-family: 'Titillium Web', gulim;
          font-size: 11px;
          font-weight: 600;
        }

        .txt1 {
          margin-top: 20px;
          color:#888;
        }

        .txt2 {
          margin: 10px 0px 5px 0px;
        }

        .txt2-1 {
          margin: 17px 0px 0px 0px;
        }

        .txt3 {
          margin: 6px 0px 1px 0px;
        }
        .txt3-1 {
          margin: 17px 0px 0px 0px;
        }
      }
    }



    .red-border {
      border: 2px solid #dd0101;

      .widget-icon {
        margin-top: 8px;
      }
    }
  }


  .contents-title {
    font-family: 'Noto Sans KR', gulim;
    font-size: 22px;
    color: #383838;
    width: 265px;
    margin-top: 10px;

    .title-bar {
      width: 40px;
      height: 3px;
      background-color: #343b3f;
      margin-bottom: 7px;
    }

    h3 {
      margin: 0px;
      font-size:20px;
    }

    .subtitle {
      font-size:10px;
      color:#888;
      margin-top:5px;
    }
  }

  .dashboard {
    /* display: flex;*/
    justify-content: space-between;

    .dashboard-chart-data {
      width: 100%;
      background-color: #fff;
      padding: 10px 10px 0px 10px;
    }

    .dashboard-notice {
      width: calc(40% - 20px);
      background-color: #fff;
      padding: 15px;

      header {
        height:30px;
        border-bottom: solid 1px #ccc;
      }

      .dashboard-table {
        thead {
          border-bottom: solid 1px #ccc;

          th {
            padding-bottom: 10px;
          }
        }

        tbody {
          td {
            padding: 10px 0px 0px 0px;
          }

          td.subject {
            color: #333;
            padding-left: 10px;
          }

          td.date {
            color: #333;
            font-size: 12px;
            text-align: center;
          }
        }
      }
    }

    .dashboard-application-list {
      width: calc(50% - 10px);
      background-color: #fff;
      height: 330px;
      margin-top: 20px;
      padding: 15px;
      overflow-y: auto;

      header {
        height:30px;
        border-bottom: solid 1px #ccc;
      }

      tr:hover {
        background-color: #f2f2f2;
      }

      td {
        padding: 5px;
        cursor: pointer;
      }

      .date {
        color: #888;
        font-size: 12px;
        margin-right: 10px;
      }

      .region {
        color: #888;
        font-size: 12px;
      }
    }

    .dashboard-installer-list {
      width: calc(50% - 10px);
      margin-left:10px;
      background-color: #fff;
      height: 330px;
      margin-top: 20px;
      padding: 15px;
      overflow-y: auto;

      header {
        height:30px;
        border-bottom: solid 1px #ccc;
      }

      tr:hover {
        background-color: #f2f2f2;
      }

      td {
        padding: 5px;
        cursor: pointer;
      }

      .date {
        color: #888;
        font-size: 12px;
        margin-right: 10px;
      }

      .region {
        color: #888;
        font-size: 12px;
      }
    }
  }
}

.note-editor.note-airframe .note-editing-area .note-editable, .note-editor.note-frame .note-editing-area .note-editable {
  background-color: #fff;
}

.fileUpload {
  margin-top:10px;

  .upload-file-name {
    cursor: pointer;
    max-width: 100px;
    max-height: 15px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .filename-extention {
    position: relative;
    top: -60px;
    display: inline-block;
    text-align: center;
    left: 0px;
    width: 120px;
    font-size: 1.2em;
    color: #fff;
    font-weight: bold;
  }
}

.MuiTypography-colorTextSecondary {
  font-size: 0.8em;
}

.home-contents {
  position: relative;
  z-index: 1;
  padding-top: 90px;
  background-color: #fff;
}

.div-readonly {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: #fff;
  opacity: 0.5;
}

.div-readonly1 {
  position: absolute;
  width: 100%;
  height: calc(58vh - 6px);
  z-index: 999;
  background-color: #fff;
  opacity: 0.8;
}

.Select-multi-value-wrapper { white-space: nowrap; overflow-x: scroll; width: 250px; }

.manufacturer-number {
  width: 100%;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.modal-search-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 0px 0px 0px;

  .MuiOutlinedInput-input {
    height: 28px;
    line-height: 28px;
    padding: 0px 10px;
    font-size: 13px;
  }
}

.part-title {
  background-color: #ccc;
  border: solid 1px #888;
  padding: 5px 10px;
}

.hover:hover {
  background-color: #f2f2f2;
}

.print-page-top {
  page-break-before:always;
  margin-top: 60px;
}

@media print {
  .print-page-top {
    page-break-before:always;
    margin-top: 0px;
  }
}