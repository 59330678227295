@keyframes opacity {
  from {opacity: 1;}
  to {opacity: 0.3;}
}
@keyframes upDown {
  from {transform: translateY(0px);}
  to {transform: translateY(10px);}
}
@keyframes leftRight {
  from {transform: translateX(10px);}
  to {transform: translateX(0px);}
}
@keyframes scale {
  from {transform: scale(1);}
  to {transform: scale(1.2);}
}
@keyframes rotate {
  from {rotate: 0;}
  to {rotate: 10deg;}
}
@keyframes rotate1 {
  from {rotate: 0;}
  to {rotate: -10deg;}
}
@keyframes rotate2 {
  from {rotate: 0;}
  to {rotate: 360deg;}
}

/* 색 이중 눈꽃 */
.snow01 {
  position: absolute;
  width: 267px;
  height: 263px;
  background: url('/assets/images/bg/snow01.png') no-repeat 50%;
  background-size: contain;
}
/* 색 눈꽃 */
.snow02 {
  position: absolute;
  width: 104px;
  height: 105px;
  background: url('/assets/images/bg/snow02.png') no-repeat 50%;
  background-size: contain;
}
/* 점선 눈꽃 */
.snow03 {
  position: absolute;
  width: 416px;
  height: 421px;
  background: url('/assets/images/bg/snow03.png') no-repeat 50%;
  background-size: contain;
}
/* 작은 눈꽃 */
.snow04 {
  position: absolute;
  width: 28px;
  height: 29px;
  background: url('/assets/images/bg/snow04.png') no-repeat 50%;
  background-size: contain;
}
/* 제일 작은 눈꽃 */
.snow05 {
  position: absolute;
  width: 23px;
  height: 24px;
  background: url('/assets/images/bg/snow05.png') no-repeat 50%;
  background-size: contain;
}

/* 큰 구름 */
.cloud01 {
  position: absolute;
  width: 95px;
  height: 36px;
  background: url('/assets/images/bg/cloud01.png') no-repeat 50%;
  background-size: contain;
}
/* 작은 구름 */
.cloud02 {
  position: absolute;
  width: 43px;
  height: 14px;
  background: url('/assets/images/bg/cloud02.png') no-repeat 50%;
  background-size: contain;
}




/* 전체메뉴 보기 bg */
.totalmenu-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;

  .snow01 {
    top: 585px;
    left: calc(50% + 540px);
  }
  .snow02 {
    top: 845px;
    left: calc(50% + 740px);
    right: 100px;
  }
  .snow03 {
    top: -70px;
    left: calc(50% - 1015px);
  }
}

/* SubHeader bg */
.subHeader-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;

  .snow01 {
    top: 195px;
    left: calc(50% + 260px);
    animation: opacity 2s infinite linear alternate;
    animation-delay: 1s;
  }
  .snow02 {
    top: 95px;
    left: calc(50% - 200px);
    animation: opacity 2s infinite linear alternate;
  }
  .snow03 {
    top: -154px;
    left: calc(50% - 790px);
    width: 376px;
    height: 380px;
    animation: opacity 3s infinite linear alternate;
    animation-delay: .5s;
  }
  .snow04 {
    top: 227px;
    left: calc(50% - 280px);
    animation: opacity 2s infinite linear alternate;
  }
  .snow04.n01 {
    top: 118px;
    left: calc(50% + 710px);
    animation: opacity 2s infinite linear alternate;
    animation-delay: .5s;
  }
  .snow05 {
    top: 300px;
    left: calc(50% - 480px);
    animation: scale 2s infinite linear alternate;
  }
  .snow05.n01 {
    top: 65px;
    left: calc(50% + 60px);
    animation: scale 2s infinite linear alternate;
    animation-delay: .5s;
  }
  .snow05.n02 {
    top: 255px;
    left: calc(50% + 568px);
    animation: scale 2s infinite linear alternate;
  }
  .cloud01 {
   top: 38px;
   left: calc(50% + 479px);
   animation: upDown 2s infinite linear alternate;
  }
  .cloud02 {
    top: 133px;
    left: calc(50% - 410px);
    animation: leftRight 2s infinite linear alternate;
  }
  .cloud02.n01 {
    top: 87px;
    left: calc(50% + 372px);
    animation: leftRight 2s infinite linear alternate;
    animation-delay: .5s;
  }
}

/* main */
.main-bg {
  position: absolute;
  top: 620px;
  left: 0;
  width: 100%;
  height: 2000px;
  z-index: -1;
  overflow: hidden;

  .snow01 {
    top: -18px;
    left: calc(50% + 570px);
    animation: opacity 3s infinite linear alternate;
    animation-delay: .5s;
  }
  .snow02 {
    top: 245px;
    left: calc(50% + 770px);
    animation: opacity 2s infinite linear alternate;
  }
  .snow03 {
    top: 380px;
    right: calc(50% + 514px);
    animation: opacity 3s infinite linear alternate;
    animation-delay: 1s;
  }
  .snow01.no2 {
    top: 1295px;
    left: auto;
    right: calc(50% + 233px);
    animation: opacity 2s infinite linear alternate;
    animation-delay: .5s;
  }
}

/* main visual */
.mainVisual-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  .img1 {
    .man {
      position: absolute;
      top: 150px;
      left: 50px;
      width: 580px;
      height: 341px;
      background: url('/assets/images/main/mainVisual/A_man.png') no-repeat 50%;
      background-size: contain;
    }
    .grass1 {
      position: absolute;
      top: 454px;
      left: 30px;
      width: 50px;
      height: 36px;
      background: url('/assets/images/main/mainVisual/A_grass1.png') no-repeat 50%;
      background-size: 100%;
      animation: rotate 1s infinite linear alternate;
    }
    .grass2 {
      position: absolute;
      top: 454px;
      left: 462px;
      width: 50px;
      height: 36px;
      background: url('/assets/images/main/mainVisual/A_grass2.png') no-repeat 50%;
      background-size: 100%;
      animation: rotate1 1s infinite linear alternate;
    }
    .light {
      position: absolute;
      top: 186px;
      left: 484px;
      width: 58px;
      height: 60px;
      background: url('/assets/images/main/mainVisual/A_light.png') no-repeat 50%;
      background-size: 100%;
      animation: upDown 1s infinite linear alternate;
    }
    .wind {
      position: absolute;
      top: 122px;
      left: 45px;
      width: 81px;
      height: 25px;
      background: url('/assets/images/main/mainVisual/A_wind.png') no-repeat 50%;
      background-size: 100%;
      animation: rotate 1s infinite linear alternate;
      &.no1 {
        top: 160px;
        left: 30px;
        animation: rotate 1s infinite linear alternate;
        animation-delay: .5s;
      }
      &.no2 {
        top: 198px;
        left: 15px;
      }
    }
  }
  .img2 {
    .man {
      position: absolute;
      top: 180px;
      left: 70px;
      width: 412px;
      height: 332px;
      background: url('/assets/images/main/mainVisual/B_man.png') no-repeat 50%;
      background-size: contain;
    }
    .sawTooth {
      position: absolute;
      top: 320px;
      left: 538px;
      width: 82px;
      height: 70px;
      background: url('/assets/images/main/mainVisual/B_sawTooth.png') no-repeat 50%;
      background-size: contain;
      animation: rotate2 3s infinite linear;
    }
    .wind1 {
      position: absolute;
      top: 177px;
      left: 485px;
      width: 42px;
      height: 44px;
      background: url('/assets/images/main/mainVisual/B_wind1.png') no-repeat 50%;
      background-size: contain;
      animation: leftRight 1s infinite linear alternate;
    }
    .wind2 {
      position: absolute;
      top: 167px;
      left: 160px;
      width: 85px;
      height: 51px;
      background: url('/assets/images/main/mainVisual/B_wind2.png') no-repeat 50%;
      background-size: contain;
      animation: leftRight 1s infinite linear alternate;
      animation-delay: .5s;
    }
  }
  .img3 {
    .man {
      position: absolute;
      top: 205px;
      left: 90px;
      width: 489px;
      height: 282px;
      background: url('/assets/images/main/mainVisual/C_man.png') no-repeat 50%;
      background-size: contain;
    }
    .sawTooth {
      position: absolute;
      top: 130px;
      left: 574px;
      width: 68px;
      height: 70px;
      background: url('/assets/images/main/mainVisual/C_sawTooth.png') no-repeat 50%;
      background-size: contain;
      animation: rotate2 3s infinite linear;
    }
    .wind1 {
      position: absolute;
      top: 415px;
      left: 420px;
      width: 42px;
      height: 44px;
      background: url('/assets/images/main/mainVisual/C_wind1.png') no-repeat 50%;
      background-size: contain;
      animation: leftRight 1s infinite linear alternate;
    }
    .wind2 {
      position: absolute;
      top: 200px;
      left: 410px;
      width: 85px;
      height: 53px;
      background: url('/assets/images/main/mainVisual/C_wind2.png') no-repeat 50%;
      background-size: contain;
      animation: leftRight 1s infinite linear alternate;
      animation-delay: .5s;
    }
  }
}

/* main visual etc bg */
@keyframes new_opacity {
  from {opacity: 1;}
  to {opacity: 0;}
}
@keyframes new_opacity1 {
  from {opacity: 1;}
  to {opacity: 0.8;}
}
@keyframes new_rotate {
  from {transform: rotate( 0 )}
  to {transform: rotate( 360deg )}
}
@keyframes new_scale {
  from {transform: scale(1);}
  to {transform: scale(0.8);}
}
@keyframes new_move {
  0% {margin: 0;}
  50% {margin: -180px 0 0 250px;}
  100% {margin: -300px 0 0 500px;}
}

@keyframes new_move {
  from {margin: 0;}
  to {margin: -300px 0 0 500px;}
}

.mainVisual-etc-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 600px;
  overflow: hidden;
  .new_snow01 {
    position: absolute;
    width: 376px;
    height: 379px;
    background: url('/assets/images/main/snow/snow01.png') no-repeat 50%;
    background-size: contain;
    top: 400px;
    left: calc(50% - 850px);
    animation: new_opacity1 1.5s infinite linear alternate;
  }
  .new_snow01.n01 {
    top: -144px;
    left: calc(50% + 475px);
    animation-delay: .3s;
  }
  .new_snow02 {
    position: absolute;
    width: 23px;
    height: 24px;
    background: url('/assets/images/main/snow/snow02.png') no-repeat 50%;
    background-size: contain;
    top: 80px;
    left: calc(50% - 445px);
    animation: new_opacity 1s infinite linear alternate;
  }
  .new_snow02.n01 {
    top: 525px;
    left: calc(50% - 225px);
    animation-delay: .5s;
  }
  .new_snow02.n02 {
    top: 50px;
    left: calc(50% - 115px);
  }
  .new_snow03 {
    position: absolute;
    width: 34px;
    height: 34px;
    background: url('/assets/images/main/snow/snow03.png') no-repeat 50%;
    background-size: contain;
    top: 235px;
    left: calc(50% + 455px);
  }
  .new_snow04 {
    position: absolute;
    width: 44px;
    height: 46px;
    background: url('/assets/images/main/snow/snow04.png') no-repeat 50%;
    background-size: contain;
    top: 375px;
    left: calc(50% - 435px);
    animation: new_move 3s infinite linear;
  }
  .new_snow05 {
    position: absolute;
    width: 23px;
    height: 24px;
    background: url('/assets/images/main/snow/snow05.png') no-repeat 50%;
    background-size: contain;
    top: 255px;
    left: calc(50% - 600px);
    animation: new_move 3s infinite linear;
  }
  .new_snow05.n01 {
    top: 45px;
    left: calc(50% + 525px);
  }
  .new_snow05.n02 {
    top: 155px;
    left: calc(50% + 180px);
  }
  .new_snow06 {
    position: absolute;
    width: 66px;
    height: 68px;
    background: url('/assets/images/main/snow/snow06.png') no-repeat 50%;
    background-size: contain;
    top: 445px;
    left: calc(50% + 285px);
    animation: new_move 5s infinite linear;
  }
  .new_snow06.n01 {
    width: 50px;
    height: 50px;
    top: 455px;
    left: calc(50% - 755px);
  }
  .new_snow07 {
    position: absolute;
    width: 50px;
    height: 50px;
    background: url('/assets/images/main/snow/snow07.png') no-repeat 50%;
    background-size: contain;
    top: 235px;
    left: calc(50% + 790px);
    animation: new_opacity 1s infinite linear alternate;
  }
}

/* 메인 - 업무안내 */
.mainWorkItem-bg {
  .img1 {
    .man {
      position: absolute;
      top: 220px;
      left: 150px;
      width: 258px;
      height: 270px;
      background: url('/assets/images/main/mainWork/A_man.png') no-repeat 50%;
      background-size: contain;
    }
    .sawTooth {
      position: absolute;
      top: 130px;
      left: 100px;
      width: 70px;
      height: 70px;
      background: url('/assets/images/main/mainWork/A_sawTooth.png') no-repeat 50%;
      background-size: contain;
      animation: rotate2 3s infinite linear;
    }
    .pot {
      position: absolute;
      top: 427px;
      left: 510px;
      width: 48px;
      height: 64px;
      background: url('/assets/images/main/mainWork/A_pot.png') no-repeat 50%;
      background-size: contain;
      animation: scale 3s infinite linear alternate;;
    }
    .cloud1 {
      position: absolute;
      top: 138px;
      left: 560px;
      width: 52px;
      height: 20px;
      background: url('/assets/images/main/mainWork/A_cloud1.png') no-repeat 50%;
      background-size: contain;
      animation: upDown 3s infinite linear alternate;
    }
    .cloud2 {
      position: absolute;
      top: 180px;
      left: 438px;
      width: 37px;
      height: 12px;
      background: url('/assets/images/main/mainWork/A_cloud2.png') no-repeat 50%;
      background-size: contain;
      animation: leftRight 3s infinite linear alternate;
    }
  }
  .img2 {
    .man {
      position: absolute;
      top: 278px;
      left: 150px;
      width: 238px;
      height: 201px;
      background: url('/assets/images/main/mainWork/B_man.png') no-repeat 50%;
      background-size: contain;
    }
    .grath {
      position: absolute;
      top: 190px;
      left: 400px;
      width: 138px;
      height: 100px;
      background: url('/assets/images/main/mainWork/B_graph.png') no-repeat 50%;
      background-size: contain;
    }
    .target {
      position: absolute;
      top: 140px;
      left: 100px;
      width: 80px;
      height: 64px;
      background: url('/assets/images/main/mainWork/B_target.png') no-repeat 50%;
      background-size: contain;
      animation: scale 3s infinite linear alternate;
    }
    .pot {
      position: absolute;
      top: 395px;
      left: 520px;
      width: 58px;
      height: 80px;
      background: url('/assets/images/main/mainWork/B_pot.png') no-repeat 50%;
      background-size: contain;
      animation: scale 3s infinite linear alternate;
    }
  }
  .img3 {
    .man {
      position: absolute;
      top: 250px;
      left: 233px;
      width: 138px;
      height: 230px;
      background: url('/assets/images/main/mainWork/C_man.png') no-repeat 50%;
      background-size: contain;
    }
    .grapth {
      position: absolute;
      top: 180px;
      left: 175px;
      width: 93px;
      height: 54px;
      background: url('/assets/images/main/mainWork/C_graph.png') no-repeat 50%;
      background-size: contain;
      animation: scale 3s infinite linear alternate;
    }
    .chart {
      position: absolute;
      top: 123px;
      left: 385px;
      width: 182px;
      height: 338px;
      background: url('/assets/images/main/mainWork/C_chart.png') no-repeat 50%;
      background-size: contain;
    }
    .magnifier {
      position: absolute;
      top: 210px;
      left: 495px;
      width: 84px;
      height: 70px;
      background: url('/assets/images/main/mainWork/C_magnifier.png') no-repeat 50%;
      background-size: contain;
      animation: scale 3s infinite linear alternate;
    }
    .pot {
      position: absolute;
      top: 390px;
      left: 0px;
      width: 43px;
      height: 91px;
      background: url('/assets/images/main/mainWork/C_pot.png') no-repeat 50%;
      background-size: contain;
      animation: rotate 3s infinite linear alternate;
    }
  }
}

@media screen and (max-width:750px) {
  /* 색 이중 눈꽃 */
  .snow01 {
    width: 26.7rem;
    height: 26.3rem;
  }
  /* 색 눈꽃 */
  .snow02 {
    width: 10.4rem;
    height: 10.5rem;
  }
  /* 점선 눈꽃 */
  .snow03 {
    width: 41.6rem;
    height: 42.1rem;
  }
  /* 작은 눈꽃 */
  .snow04 {
    width: 2.8rem;
    height: 2.9rem;
  }
  /* 제일 작은 눈꽃 */
  .snow05 {
    width: 2.3rem;
    height: 2.4rem;
  }

  /* 큰 구름 */
  .cloud01 {
    width: 9.5rem;
    height: 3.6rem;
  }
  /* 작은 구름 */
  .cloud02 {
    width: 4.3rem;
    height: 1.4rem;
  }

  /* SubHeader bg */
  .subHeader-bg {
    .snow01 {
      top: 19.5rem;
      left: auto;
      right: -7rem;
    }
    .snow02 {
      top: 14rem;
      left: 17rem;
    }
    .snow03 {
      top: -8.6rem;
      left: -19rem;
      width: 37.6rem;
      height: 38rem;
    }
    .snow04 {
      display: none;
    }
    .snow04.n01 {
      display: none;
    }
    .snow05 {
      top: 9rem;
      left: 55rem;
    }
    .snow05.n01 {
      display: none;
    }
    .snow05.n02 {
      display: none;
    }
    .cloud01 {
      display: none;
    }
    .cloud02 {
      display: none;
    }
    .cloud02.n01 {
      display: none;
    }
  }

  /* main visual etc bg */

  .mainVisual-etc-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 105rem;
    overflow: hidden;
    .new_snow01 {
      position: absolute;
      width: 37.6rem;
      height: 37.9rem;
      background: url('/assets/images/main/snow/snow01.png') no-repeat 50%;
      background-size: contain;
      top: 85rem;
      left: -1.2rem;
      animation: new_opacity1 1.5s infinite linear alternate;
    }
    .new_snow01.n01 {
      top: -10rem;
      left: 50rem;
      animation-delay: .3s;
    }
    .new_snow02 {
      position: absolute;
      width: 23px;
      height: 24px;
      background: url('/assets/images/main/snow/snow02.png') no-repeat 50%;
      background-size: contain;
      top: 80px;
      left: calc(50% - 445px);
      animation: new_opacity 1s infinite linear alternate;
    }
    .new_snow02.n01 {
      top: 525px;
      left: calc(50% - 225px);
      animation-delay: .5s;
    }
    .new_snow02.n02 {
      top: 50px;
      left: calc(50% - 115px);
    }
    .new_snow03 {
      position: absolute;
      width: 34px;
      height: 34px;
      background: url('/assets/images/main/snow/snow03.png') no-repeat 50%;
      background-size: contain;
      top: 235px;
      left: calc(50% + 455px);
    }
    .new_snow04 {
      position: absolute;
      width: 44px;
      height: 46px;
      background: url('/assets/images/main/snow/snow04.png') no-repeat 50%;
      background-size: contain;
      top: 375px;
      left: calc(50% - 435px);
      animation: new_move 3s infinite linear;
    }
    .new_snow05 {
      position: absolute;
      width: 23px;
      height: 24px;
      background: url('/assets/images/main/snow/snow05.png') no-repeat 50%;
      background-size: contain;
      top: 255px;
      left: calc(50% - 600px);
      animation: new_move 3s infinite linear;
    }
    .new_snow05.n01 {
      top: 45px;
      left: calc(50% + 525px);
    }
    .new_snow05.n02 {
      top: 155px;
      left: calc(50% + 180px);
    }
    .new_snow06 {
      position: absolute;
      width: 6.6rem;
      height: 6.8rem;
      background: url('/assets/images/main/snow/snow06.png') no-repeat 50%;
      background-size: contain;
      top: 6rem;
      left: 57rem;
      animation: new_move 5s infinite linear;
    }
    .new_snow06.n01 {
      width: 50px;
      height: 50px;
      top: 455px;
      left: calc(50% - 755px);
    }
    .new_snow07 {
      position: absolute;
      width: 50px;
      height: 50px;
      background: url('/assets/images/main/snow/snow07.png') no-repeat 50%;
      background-size: contain;
      top: 235px;
      left: calc(50% + 790px);
      animation: new_opacity 1s infinite linear alternate;
    }
  }

  /* 메인 - 업무안내 */
  .mainWorkItem-bg {
    .img1 {
      .man {
        position: absolute;
        top: 13.5rem;
        left: 6rem;
        width: 25.8rem;
        height: 27rem;
        background: url('/assets/images/main/mainWork/A_man.png') no-repeat 50%;
        background-size: contain;
      }
      .sawTooth {
        position: absolute;
        top: 3.5rem;
        left: 0;
        width: 7rem;
        height: 7rem;
        background: url('/assets/images/main/mainWork/A_sawTooth.png') no-repeat 50%;
        background-size: contain;
        animation: rotate2 3s infinite linear;
      }
      .pot {
        position: absolute;
        top: 33.5rem;
        left: 48rem;
        width: 4.8rem;
        height: 6.4rem;
        background: url('/assets/images/main/mainWork/A_pot.png') no-repeat 50%;
        background-size: contain;
        animation: scale 3s infinite linear alternate;
      }
      .cloud1 {
        position: absolute;
        top: 3.5rem;
        left: 49rem;
        width: 5.2rem;
        height: 2rem;
        background: url('/assets/images/main/mainWork/A_cloud1.png') no-repeat 50%;
        background-size: contain;
        animation: upDown 3s infinite linear alternate;
      }
      .cloud2 {
        position: absolute;
        top: 8.5rem;
        left: 35.5rem;
        width: 3.7rem;
        height: 1.2rem;
        background: url('/assets/images/main/mainWork/A_cloud2.png') no-repeat 50%;
        background-size: contain;
        animation: leftRight 3s infinite linear alternate;
      }
    }
    .img2 {
      .man {
        position: absolute;
        top: 23.5rem;
        left: 5.5rem;
        width: 23.8rem;
        height: 20.1rem;
        background: url('/assets/images/main/mainWork/B_man.png') no-repeat 50%;
        background-size: contain;
      }
      .grath {
        position: absolute;
        top: 13.5rem;
        left: 36rem;
        width: 11.8rem;
        height: 10rem;
        background: url('/assets/images/main/mainWork/B_graph.png') no-repeat 50%;
        background-size: contain;
      }
      .target {
        position: absolute;
        top: 7.5rem;
        left: 0;
        width: 8rem;
        height: 6.4rem;
        background: url('/assets/images/main/mainWork/B_target.png') no-repeat 50%;
        background-size: contain;
        animation: scale 3s infinite linear alternate;
      }
      .pot {
        position: absolute;
        top: 35rem;
        left: 49rem;
        width: 5.8rem;
        height: 8rem;
        background: url('/assets/images/main/mainWork/B_pot.png') no-repeat 50%;
        background-size: contain;
        animation: scale 3s infinite linear alternate;
      }
    }
    .img3 {
      .man {
        position: absolute;
        top: 21rem;
        left: 19rem;
        width: 13.8rem;
        height: 23rem;
        background: url('/assets/images/main/mainWork/C_man.png') no-repeat 50%;
        background-size: contain;
      }
      .grapth {
        position: absolute;
        top: 13.5rem;
        left: 12rem;
        width: 9.3rem;
        height: 5.4rem;
        background: url('/assets/images/main/mainWork/C_graph.png') no-repeat 50%;
        background-size: contain;
        animation: scale 3s infinite linear alternate;
      }
      .chart {
        position: absolute;
        top: 8rem;
        left: 37rem;
        width: 18.2rem;
        height: 33.8rem;
        background: url('/assets/images/main/mainWork/C_chart.png') no-repeat 50%;
        background-size: contain;
      }
      .magnifier {
        position: absolute;
        top: 16rem;
        left: 47.5rem;
        width: 8.4rem;
        height: 7rem;
        background: url('/assets/images/main/mainWork/C_magnifier.png') no-repeat 50%;
        background-size: contain;
        animation: scale 3s infinite linear alternate;
      }
      .pot {
        position: absolute;
        top: 33.5rem;
        left: 0;
        width: 4.3rem;
        height: 9.1rem;
        background: url('/assets/images/main/mainWork/C_pot.png') no-repeat 50%;
        background-size: contain;
        animation: rotate 3s infinite linear alternate;
      }
    }
  }
}
