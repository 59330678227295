.ck-source-editing-area {
  textarea {
    max-height: 700px;
    overflow: auto !important;
  }
}

.ckeditor {
  .editor-container {
    height: 400px !important;
  }

  .ck-editor__editable {
    height: 400px !important;
  }

  .ck-editor__editable_inline {
    min-height: 100%;
  }

}