body {
  background: #ddd;
}

.plr40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.btn-area {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  padding: 10px;
  margin: 0 auto;
  background: #fff;
  border: 1px solid #ddd;
  text-align: right;
  z-index: 9;
}

.equipment-print {
  position: relative;
  width: 20.9cm;
  min-height: 29.5cm;
  padding: 50px 40px 0;
  margin: 0 auto 10px;
  background: #fff;
}

.report-print {
  position: relative;
  width: 20.9cm;
  min-height: 29.5cm;
  padding: 50px 0px 0;
  margin: 0 auto 10px;
  background: #fff;
}


/* 검사신청서 */
.equipment-print1 {
  .print-header {
    .title {
      font-size: 35px;
      font-weight:500;
      text-align: center;
      margin-bottom: 50px;
    }
    .guide-txt {
      font-size: 12px;
      margin-bottom: 25px;
    }
    .no {
      font-size: 12px;
      margin-bottom: 10px;
      span {
        font-size: 13px;
        font-weight: 500;
      }
    }
  }

  .report-tbl {
    table-layout: fixed;
    th {
      font-size: 13px;
      height: 25px;
      border: 1px solid #aaa;
      text-align: center;
    }
    td {
      font-size: 12px;
      height: 21px;
      border: 1px solid #aaa;
      text-align: center;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      &.tl {
        text-align: left;
        padding-left: 5px;
      }
    }
    tbody th {
      font-size: 12px;
      font-weight: 500;
    }
    tfoot td {
      font-size: 13px;
    }
  }

  .print-footer {
    position: absolute;
    left: 40px;
    bottom: 50px;
    width: calc(100% - 80px);
    .footer1 {
      .sign {
        float: left;
        width: 100px;
        height: 75px;
        border: 1px solid #aaa;
        margin-left: 100px;
        .th {
          font-size: 13px;
          font-weight: 500;
          line-height: 23px;
          height: 25px;
          text-align: center;
          border-bottom: 1px solid #222;
        }
      }
      .info {
        float: right;
        width: 250px;
        th, td {
          font-size: 13px;
          font-weight: 500;
          text-align: left;
          height: 25px;
        }
      }
    }
    .footer2 {
      padding-top: 30px;
      .ceo {
        font-size: 24px;
        margin-bottom: 20px;
        span {
          font-size: 12px;
          padding-left: 5px;
        }
      }
      .com {
        float: left;
        font-size: 12px;
      }
      .document-no {
        float: right;
        font-size: 12px;
      }
    }
  }
}

/* 특정설비신규(재)검사신청서 */
.equipment-print2 {
  .print-header {
    .document-no {
      font-size: 12px;
      margin-bottom: 30px;
    }
    .title {
      font-size: 35px;
      font-weight:500;
      text-align: center;
      margin-bottom: 20px;
    }
    .guide-txt {
      font-size: 12px;
    }
    .date {
      height: 20px;
      background: #ddd;
      margin: 5px 0 10px;
      div {
        float: left;
        width: calc(100% / 3);
        font-size: 12px;
        line-height: 20px;
        padding-left: 10px;
      }
    }
  }

  .report-tbl {
    table-layout: fixed;
    margin: 10px 0;
    th {
      font-size: 12px;
      height: 35px;
      border: 1px solid #aaa;
      text-align: center;
    }
     td {
      font-size: 12px;
      line-height: 1.2;
      height: 35px;
      padding: 5px;
      border: 1px solid #aaa;
      text-align: left;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      &.tc {
        text-align: center;
      }
      &.tr {
        text-align: right;
      }
    }
  }

  .print-footer {
    .footer1 {
      padding-top: 50px;
      .report-txt {
        font-size: 16px;
        text-indent: 20px;
        padding-left: 50px;
        margin: 10px 0 20px;
      }
      .date {
        font-size: 14px;
        text-align: right;
      }
      .user {
        font-size: 12px;
        text-align: right;
        margin: 10px 0 20px;
        span {
          font-size: 14px;
        }
      }
      .ceo {
        font-size: 24px;
        padding-left: 50px;
        margin-bottom: 20px;
        span {
          font-size: 12px;
          padding-left: 5px;
        }
      }
    }
    .footer2 {
      .report-tbl {
        margin-bottom: 2px;
        tr {
          th {border-left: none;}
          td:nth-last-child(1) {border-right: none;}
        }
      }
      .step-tbl {
        border-top: 1px solid #222;
        tr {
          border-top: 1px solid #222;
          border-bottom: 1px solid #222;
        }
        th {
          font-size: 12px;
          font-weight: 500;
          height: 25px;
          background: #ddd;
        }
        .step {
          height: 80px;
          background: url('/assets/images/inp_process.png') no-repeat 50% / contain;
        }
      }
      .document-size {
        float: right;
        font-size: 10px;
      }
    }
  }
}

/* 응축기 검사표 */
.equipment-print3 {
  .print-header {
    .title {
      font-size: 26px;
      font-weight:500;
      text-align: center;
      text-decoration: underline;
      margin-bottom: 30px;
    }
    .date {
      div {
        font-size: 13px;
      }
    }
  }

  .report-tbl {
    table-layout: fixed;
    margin: 10px 0;
    th {
      font-size: 12px;
      height: 25px;
      border: 1px solid #aaa;
      text-align: center;
    }
     td {
      font-size: 12px;
      height: 25px;
      padding: 0 10px;
      border: 1px solid #aaa;
      text-align: left;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      &.tc {
        text-align: center;
        padding: 0;
      }
      .bold {
        margin-left: -5px;
      }
      span {padding: 0 20px;}
      .space {padding-left: 16px;}
    }
    &.tc {
      th,td {text-align: center;}
    }
  }

  .print-footer {
    .footer1 {
      padding-top: 5px;
      .user {
        float: left;
        font-size: 12px;
        padding-top: 25px;
        span {
          display: inline-block;
          width: 160px;
        }
      }
      .sign {
        float: right;
        width: 100px;
        height: 75px;
        border: 1px solid #aaa;
        margin-bottom: 20px;
        .th {
          font-size: 13px;
          font-weight: 500;
          line-height: 23px;
          height: 25px;
          text-align: center;
          border-bottom: 1px solid #222;
        }
      }
      .com {
        float: left;
        font-size: 12px;
      }
      .document-no {
        float: right;
        font-size: 12px;
      }
    }
  }
}

/* 냉매설비[응축기]시험성적서 */
.equipment-print4 {
  .print-header {
    .title {
      font-size: 26px;
      font-weight:500;
      text-align: center;
      text-decoration: underline;
      margin-bottom: 30px;
    }
    .no , .date{
      font-size: 12px;
    }
  }

  .report-tbl {
    table-layout: fixed;
    margin: 10px 0;
    th {
      font-size: 11px;
      font-weight: 500;
      height: 24px;
      border: 1px solid #aaa;
      text-align: center;
    }
     td {
      font-size: 11px;
      height: 24px;
      padding: 0;
      border: 1px solid #aaa;
      text-align: center;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      &.tc {
        text-align: center;
      }
      .bold {
        margin-left: -5px;
      }
      .unit {
        float: right;
        padding-right: 10px;
      }
      .fr {
        padding-right: 10px;
      }
    }
    &.tr {
      td {
        text-align: right;
        padding-right: 10px;
      }
    }
    &.tl {
      td {
        text-align: left;
        padding-left: 10px;
      }
    }
  }
  .tbl-title {
    font-size: 13px;
    font-weight: bold;
    color: green;
    padding-left: 20px;
    background: url('/assets/images/ball_green.gif') no-repeat 0 50%;
  }
  .table-wrap {
    .tbl1 {
      float: left;
      width: 25%;
    }
    .tbl2 {
      float: left;
      width: calc(50% - 2px);
      margin: 0 1px;
    }
  }
  .print-footer {
    position: absolute;
    left: 40px;
    bottom: 50px;
    width: calc(100% - 80px);
    .footer1 {
      padding-top: 50px;
      .report-txt {
        font-size: 12px;
        line-height: 1.2;
        text-align: center;
        margin: 10px 0 20px;
      }
      .date {
        font-size: 14px;
        text-align: center;
      }
      .user {
        font-size: 12px;
        text-align: right;
        margin: 10px 0 20px;
        span {
          font-size: 14px;
          padding-right: 30px;
        }
      }
      .sign {
        float: right;
        width: 100px;
        height: 75px;
        border: 1px solid #aaa;
        margin-bottom: 20px;
        .th {
          font-size: 13px;
          font-weight: 500;
          line-height: 23px;
          height: 25px;
          text-align: center;
          border-bottom: 1px solid #222;
        }
      }
      .com {
        float: left;
        font-size: 12px;
      }
      .document-no {
        float: right;
        font-size: 12px;
      }
    }
  }
}

/* 가스열펌프(GHP) 제품표시 조회확인서 */
.ghp-repair-report {
  .print-header {
    .pageno {
      font-size: 12px;
      padding: 5px;
      background-color: #ddd;
      margin-bottom: 30px;
    }
    .title {
      font-size: 26px;
      font-weight:500;
      text-align: center;
      margin-bottom: 30px;
    }
  }
  .section-title {
    font-size: 15px;
    font-weight: bold;
    margin: 20px 0 10px;
  }
  .txt {
    font-size: 14px;
    line-height: 20px;
    padding-left: 15px;
  }


  .report-tbl {
    table-layout: fixed;
    margin: 0;
    border: 2px solid #aaa;
    th {
      font-size: 13px;
      font-weight: 500;
      height: 30px;
      border: 1px solid #aaa;
      text-align: center;
    }
     td {
      font-size: 13px;
      height: 30px;
      padding: 0;
      border: 1px solid #aaa;
      text-align: center;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      &.tc {
        text-align: center;
      }
      .bold {
        margin-left: -5px;
      }
      .unit {
        float: right;
        padding-right: 10px;
      }
      .fr {
        padding-right: 10px;
      }
    }
    &.tr {
      td {
        text-align: right;
        padding-right: 10px;
      }
    }
    &.tl {
      td {
        text-align: left;
        padding-left: 10px;
      }
    }
    thead th {
      //border-bottom: 
    }
  }

  .date {
    font-size: 14px;
    text-align: center;
  }
  .user {
    font-size: 14px;
    text-align: center;
    width: 500px;
    margin: 10px auto 20px;
    span {
      font-size: 14px;
      padding-right: 30px;
    }
  }
  .ceo {
    font-size: 25px;
    text-align: center;
    margin: 20px 0;
    span {
      font-size: 17px;
    }

  }

  .print-footer {
    position: absolute;
    left: 40px;
    bottom: 50px;
    width: calc(100% - 80px);
    .footer1 {
      padding-top: 50px;
      .report-txt {
        font-size: 12px;
        line-height: 1.2;
        text-align: center;
        margin: 10px 0 20px;
      }

      .sign {
        float: right;
        width: 100px;
        height: 75px;
        border: 1px solid #aaa;
        margin-bottom: 20px;
        .th {
          font-size: 13px;
          font-weight: 500;
          line-height: 23px;
          height: 25px;
          text-align: center;
          border-bottom: 1px solid #222;
        }
      }
      .com {
        float: left;
        font-size: 12px;
      }
      .document-no {
        float: right;
        font-size: 12px;
      }
    }
  }
}


.page-break {
  margin: 100px 0px;
  page-break-before: always;
  height: 10px;
  background-color: #ddd;
}

.print-page-break {
  margin: 0px 0px;
  page-break-before: always;
  height: 10px;
  background-color: #ddd;
}


@page {
  size: A4 portrait;
  margin: 0;
}

@media print {
  body {
    padding: 0;
  }
  .btn-area {
    display:none;
  }
  .report {
    margin: 0;
    width: 100%;
    page-break-after: always;
  }
  * {
		-webkit-print-color-adjust: exact;
		print-color-adjust: exact;
  }

  .page-break {
    margin: 100px 0px;
    page-break-before: always;
    height: 0px;
    background-color: transparent;
  }

  .print-page-break {
    margin: 0px 0px;
    page-break-before: always;
    height: 0px;
    background-color: #ddd;
  }
}

@page {
  size: auto;
  margin: 0;  /* this affects the margin in the printer settings */
}

.print-footer1 {
  position: static !important;
  margin: 0 auto;
  padding-bottom: 100px;
}