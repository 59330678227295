body {
  background: #ddd;
}
.btn-area {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  padding: 10px;
  margin: 0 auto;
  background: #fff;
  border: 1px solid #ddd;
  text-align: right;
  z-index: 9;
}

.ghp-print {
  position: relative;
  width: 20.9cm;
  height: 29.5cm;
  padding: 50px 40px 0;
  margin: 0 auto 10px;
  background: #fff;
  .print-header {
    .title {
      font-size: 26px;
      font-weight:500;
      text-align: center;
      text-decoration: underline;
      margin-bottom: 15px;
    }
    .title1 {
      font-size:20px;
      font-weight: 300;
      text-align: center;
      margin-bottom: 25px;
    }
    .date {
      div {
        font-size: 13px;
      }
    }
  }

  .report-tbl {
    table-layout: fixed;
    margin: 10px 0;
    th {
      font-size: 12px;
      height: 27px;
      border: 1px solid #aaa;
      text-align: center;
    }
     td {
      font-size: 12px;
      height: 27px;
      padding: 0 8px;
      border: 1px solid #aaa;
      text-align: left;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      &.tc {
        text-align: center;
        padding: 0;
      }
      .bold {
        margin-left: -5px;
      }
      span {padding: 0 20px;}
      .space {padding-left: 16px;}
    }
    &.tc {
      th,td {text-align: center;}
    }
    &.td20 {
      td {height: 20px;}
    }
  }

  .print-footer {
    .footer1 {
      padding-top: 20px;
      .user {
        float: left;
        font-size: 12px;
        padding-top: 25px;
        span {
          display: inline-block;
          width: 80px;
        }
      }
      .sign {
        float: right;
        width: 100px;
        height: 75px;
        border: 1px solid #aaa;
        margin-bottom: 20px;
        .th {
          font-size: 13px;
          font-weight: 500;
          line-height: 23px;
          height: 25px;
          text-align: center;
          border-bottom: 1px solid #222;
        }
      }
    }
    .com {
      float: left;
      font-size: 12px;
    }
    .document-no {
      float: right;
      font-size: 12px;
    }
  }
}

@page {
  size: A4 portrait;
  margin: 0;
}

@media print {
  body {
    padding: 0;
  }
  .btn-area {
    display:none;
  }
  .report {
    margin: 0;
    width: 100%;
    page-break-after: always;
  }
}

