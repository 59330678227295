.popup-layer {
  position:absolute;
  border:solid 1px #ddd;
  z-index:10000;
  box-shadow: 0 1px 6px 0 rgba(32,33,36,0.28);
  background-color:#fff;
  max-width:100%;

  .popup-subject {
    padding: 10px;
    font-size: 16px;
    font-family: 'NanumSquare', sans-serif;
    width: 100%;
    color: #333;
    font-weight: 400;
    line-height: 1.5;
    -webkit-text-size-adjust: none;
    word-break: keep-all;
  }

  .popup-preview-contents {
    width:100%;
    height:calc(100% - 88px);
    overflow-y:auto;
    overflow-x:hidden;
  }

  .popup-not-today {
    line-height:40px;
    font-size:14px;
  }

  .popup-button-area {
    padding: 15px;
    color: #fff;
    height: 40px;
    text-align: right;
    padding: 0px 20px;
    border-top: 1px solid #e5e5e5;
    background-color: #939393;
    
    button {
      color: #fff;
      background-color: transparent; 
      margin-top: 8px !important;
    } 
  }
  
  .popup-header {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    letter-spacing: -1px;
    line-height: 50px;    
    height: 50px;
    padding-left: 20px;
    background-color: #1e57a1;
  }
}