.board-home {
  padding-top: 100px;
  padding-bottom: 50px;
  .board-title {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: -1px;
    padding-bottom: 25px;
  }
  .board-header {
    margin-bottom: 10px;
    .cnt {
      float: left;
      font-size: 14px;
      font-weight: 500;
      color: #777;
      letter-spacing: -1px;
      line-height: 37px;
    }
    .selectBox {
      width: 120px;
      height: 35px;
      .MuiInputBase-root .MuiInputBase-input {
        height: 35px;
        line-height: 1;
        padding: 0 10px;
        border-radius: 0;
        border: 1px solid #ddd;
        border-right: none;
      }
      .MuiInput-underline:before, .MuiInput-underline:hover:before {
        border-bottom: none;
      }
    }
    .input {
      width: 190px;
      height: 35px;
      margin-right: 5px;      
      .MuiOutlinedInput-input {
        height: 35px;
        padding: 0 10px;
        border-left: none;
      }
      .MuiOutlinedInput-root {
        border-radius: 0;
      }
    }
    .HBtn {
      height: 35px;
    }
  }
  .list-table.board-table {
    border-top: 1px solid #d3d6dc;
    th, td {
      height: 47px;
      border-left: none;
      border-right: none;
    }
  }
  .pagination {
    margin: 40px auto 150px;

    .MuiPagination-ul {
      justify-content: center;
      
      .MuiPaginationItem-root {
        width: 35px;
        min-width: 35px;
        height: 35px;
        border: 1px solid #d5d5d5;  
        padding: 0;
        margin: 0 5px 0 0;
        border-radius: 50%;
        color: #333;
  
        svg {
          color: #000;
        }
  
        &.Mui-selected {
          background: #e3eefa;   
          border-color: #8cbae9; 
        }
      }  
      .MuiPaginationItem-ellipsis {
        border: none;
      }
    }  
  }
  .list-table.board-view-table {
    .col1 {width: 15%;}
    .col2 {width: 85%;}
    td {
      text-align: left;
      padding: 30px;
      border-left: none;
      border-right: none;
    }
    .title-tr {
      td {
        border: none;
      }      
      .title {
        font-size: 24px;
        font-weight: bold;
        letter-spacing: -1px;
        text-align: center;
        padding: 65px 0;
        background: #f0f3f9;
        border-radius: 0 30px 30px 30px;
        margin-bottom: 20px;
      }
      .date {
        font-size: 14px;
        font-weight: 500;
        color: #333;
        letter-spacing: -1px;
        text-align: right;
        span {
          color: #333;
          &:before {
            display: inline-block;
            content: '';
            width: 1px;
            height: 10px;
            margin: 0 10px;
            background-color: #cfcfcf;
            vertical-align: middle;
          }  
        }
      }
    }
    .board-contents {
      td {
        padding: 40px 40px 100px;
        border: none;
        img {
          max-width: 100%;
        }
      }      
    }
    .file-tr {
      th {
        font-size: 13px;
        background-color: #f4f5f7;
        border-left: none;
      }
      td {
        text-align: left;
        .file {
          display: block;
          font-size: 14px;
          font-weight: 300;
          letter-spacing: -1px;
          line-height: 24px;
          &:before {
            display: inline-block;
            content: '';
            width: 2px;
            height: 2px;
            margin-right: 5px;
            background-color: #777;
            vertical-align: middle;
          }  
        }
      }
    }
  }
  .Btn.list-btn {
    display: block;
    width: 150px;
    height: 44px;
    font-size: 17px;
    color: #222;
    letter-spacing: -1px;
    border: 1px solid #d3d5db;
    border-radius: 4px;
    margin: 50px auto 150px;
  }
}

@media screen and (max-width:750px) {
  .board-home {
    padding: 17rem 4.8rem 0 !important;
    .board-title {
      font-size: 3rem;
      font-weight: bold;
      letter-spacing: -1px;
      padding-bottom: 2.5rem;
    }
    .board-header {
      margin-bottom: 2.5rem;
      .cnt {
        float: none;
        font-size: 2.4rem;
        font-weight: 500;
        color: #777;
        letter-spacing: -1px;
        line-height: 1;
        margin-bottom: 2rem;
      }
      .selectBox {
        font-size: 2.4rem;
        width: 22rem;
        height: 6.6rem;
        margin: 0 1rem 2rem 0;
        .MuiInputBase-root .MuiInputBase-input {
          font-size: 2.4rem;
          height: 6.6rem;
          padding: 0 2rem;
          border-radius: 0;
          border-right: 1px solid #d6d6d6;
        }
      }
      .input {
        font-size: 2.4rem;
        width: calc(100% - 23rem);
        height: 7rem;
        margin: 0 0 1rem;      
        .MuiOutlinedInput-input {
          font-size: 2.4rem;
          height: 7rem;
          padding: 0 2rem;
          border-left: none;
        }
        .MuiOutlinedInput-root {
          border-radius: 0;
        }
      }
      .HBtn {
        font-size: 2.8rem;
        width: calc(100vw - 9.6rem);
        height: 7rem;
      }
    }
    .list-table.board-table {
      width: calc(100vw - 9.6rem);
      tr, th, td {
        display: block;
        text-align: center;
        width: calc(100vw - 9.6rem);
        height: auto;
        border: none;
        text-align: left;
      }
      thead {
        th {
          font-size: 2.4rem;
          line-height: 8rem;
          text-align: center;
          padding: 0;
        }
        th:nth-child(1) {
          display: none;
        }
        th:nth-child(2) {
          float: left;
          width: calc((100vw - 9.6rem) * 0.75);
        }   
        th:nth-child(3) {
          float: left;
          width: calc((100vw - 9.6rem) * 0.25);      
        }        
      }
      tr {
        border-bottom: 1px solid #e3e7eb;
        td:nth-child(1) {
          display: none;
        }
        td:nth-child(2) {
          float: left;
          width: 75%;
          font-size: 2.4rem;
          line-height: 1.3;
          padding: 2.5rem 2rem;
        }
        td:nth-child(3) {
          float: left;
          width: 25%;
          font-size: 2.4rem;
          line-height: 1.3;
          padding: 2.5rem 2rem;
        }
        &::after {
          content: "";
          display: block;
          clear: both;
        }
      }
    }
    .pagination {
      margin: 4rem auto 15rem;

      .MuiPagination-ul {
        justify-content: center;
        
        .MuiPaginationItem-root {
          font-size: 2rem;
          width: 5rem;
          min-width: 5rem;
          height: 5rem;
          border: 1px solid #d5d5d5;  
          padding: 0;
          margin: 0 0.3rem;
          border-radius: 50%;
          color: #333;
    
          svg {
            color: #000;
          }
    
          &.Mui-selected {
            background: #e3eefa;    
            border-color: #8cbae9;
          }
        }  
        .MuiPaginationItem-ellipsis {
          border: none;
        }
      }  
    }
    .list-table.board-view-table {
      width: calc(100vw - 9.6rem);
      .col1 {width: 30%;}
      .col2 {width: 70%;}
      td {
        text-align: left;
        padding: 3rem 0;
        border-left: none;
        border-right: none;
      }
      .title-tr {
        .title {
          font-size: 3rem;
          font-weight: bold;
          color: #5b646e;
          line-height: 4rem;
          letter-spacing: -1px;
          padding: 5rem 3rem;
          margin-bottom: 3rem;
        }
        .date {
          font-size: 2.2rem;
          font-weight: 500;
          color: #333;
          letter-spacing: -1px;
          text-align: left;
          padding-left: 3rem;
          span {
            color: #333;
            &:before {
              display: inline-block;
              content: '';
              width: 1px;
              height: 10px;
              margin: 0 2rem;
              background-color: #cfcfcf;
              vertical-align: middle;
            }  
          }
        }
      }
      .board-contents {
        td {
          padding: 4rem 0 8rem;
          width: calc(100vw - 9.6rem);
        }      
      }
      .file-tr {
        th {
          font-size: 2.4rem;
          border-left: none;
        }
        td {
          text-align: left;
          padding: 3rem;
          .file {
            display: block;
            font-size: 2.4rem;
            font-weight: 300;
            letter-spacing: -1px;
            line-height: 4.4rem;
            &:before {
              display: inline-block;
              content: '';
              width: 2px;
              height: 2px;
              margin-right: 0.5rem;
              background-color: #777;
              vertical-align: middle;
            }  
          }
        }
      }
    }
    .Btn.list-btn {
      display: block;
      width: 100%;
      height: 8rem;
      font-size: 2.8rem;
      border-radius: 0;
      margin: 4rem auto 15rem;
    }
  }
}  