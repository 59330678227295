/* footer */
.home-footer {
  background: #35373b;
  .mobile-only {display: none;}
  .footer1 {
    .flink {
      display: flex;
      justify-content: center;
      padding: 20px;
      background: #f1f2f4;
      li {
        float: left;
        padding: 0 10px;
        > a {
          display: block;
          width: 175px;
          height: 40px;
          text-indent: -9999px;
          background-size: contain;
        }
        &:nth-child(1) > a {background-image: url('/assets/images/footer/flink01.png');}
        &:nth-child(2) > a {background-image: url('/assets/images/footer/flink02.png');}
        &:nth-child(3) > a {background-image: url('/assets/images/footer/flink03.png');}
        &:nth-child(4) > a {background-image: url('/assets/images/footer/flink04.png');}
      }

      .swiper-slide {
        max-width: 195px;
        padding: 0 10px;
        img {
          width: 175px;
        }
      }
    }
    .fmenu {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;
      background: #202124;
      li {
        float: left;
        padding: 0 25px;
        .fmenu-list {
          font-size: 15px;
          font-weight: 500;
          color: #fff;
          letter-spacing: -1px;
        }
        &:nth-last-child(1) {
          padding-right: 0;
        }
      }
    }
  }
  .footer2 {
    padding: 30px 0;
    .footer-logo {
      float: left;
      margin: 30px 30px 0 0;
    }
    .addr {
      float: left;
      margin-top: 30px;
      > p {
        font-size: 13px;
        font-weight: 500;
        color: #999;
        line-height: 23px;
      }
      .line {
        display: inline-block;
        width: 1px;
        height: 9px;
        margin: 0 10px;
        background-color: #bcbcbc;
      }
    }
    .tel {
      float: right;
      width: 310px;
      padding: 22px 40px;
      border-radius: 20px;
      background-color: #35373b;
      .tel-title {
        font-size: 14px;
        color: #999;
        margin: 0 0 14px;
      }
      .telno {
        font-size: 20px;
        font-weight: 500;
        color: #fff;
        line-height: 30px;

        &.no1 {
          margin-bottom: 10px;
        }

        span {
          float: left;
          font-size: 14px;
          font-weight: 500;
          line-height: 30px;
          color: #999;
          text-align: center;
          width: 50px;
          height: 30px;
          border-radius: 15px;
          background-color: #202124;
          margin-right: 10px;
        }
      }
    }
  }
  .to-top {
    position: fixed;
    bottom: 60px;
    right: 60px;
    width: 50px;
    height: 50px;
    filter: drop-shadow(0 0 12px rgba(66,66,66,0.2));
    background: #fff url('/assets/images/footer/icon_top.png') no-repeat 50%;
    border-radius: 0 10px 10px 10px;
    z-index: 9;
  }
}

@media screen and (max-width:1200px) {
  .home-footer {
    .footer2 {
      padding: 30px;
      .footer-logo {
        float: none;
        display: block ;
        margin: 0 0 30px;
      }
      .addr {
        float: none;
      }
      .tel {
        float: none;
        width: 100%;
        background: #202124;
        .telno {
          float: left;
          padding-right: 30px;

          span {
            background-color: #35373b;
          }

          &.no1 {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width:800px) {
  .home-footer {
    .footer1 {
      .flink {
        ul {
          width: 390px;
          li:nth-child(1), li:nth-child(2) {
            margin-bottom: 10px;
          }
        }

      }
    }
  }
}

@media screen and (max-width:750px) {
  .home-footer {
    .pc-only {display: none;}
    .mobile-only {display: block;}
    .footer1 {
      .fmenu {
        width: 100%;
        height: 90px;
        padding: 0 4.8rem;
        ul {
          display: flex;
          justify-content: space-between;
          width: 100%;
        }
        li {
          padding: 0;
          .fmenu-list {
            font-size: 2.4rem;
            font-weight: 500;
            line-height: 12.4rem;
          }
        }
      }
      .flink {
        padding: 4.8rem;
        ul {
          width: 100%;
          li {
            float: left;
            width: calc(50% - 2.4rem);
            padding: 0;
            margin-right: 4.8rem;
            > a {
              width: 30.3rem;
              height: 8rem;
              background-size: contain;
            }
            &:nth-child(1) > a {background-image: url('/assets/images/footer/flink01m.png');}
            &:nth-child(2) > a {background-image: url('/assets/images/footer/flink02m.png');}
            &:nth-child(3) > a {background-image: url('/assets/images/footer/flink03m.png');}
            &:nth-child(4) > a {background-image: url('/assets/images/footer/flink04m.png');}
          }
          li:nth-child(1), li:nth-child(2) {
            margin-bottom: 3rem;
          }
          li:nth-child(2), li:nth-child(4) {
            margin-right: 0;
          }
        }

        .swiper-slide {
          max-width: 33.3rem;
          padding: 0 3rem 0 0;
          img {
            width: 30.3rem;
          }
        }
      }
    }
    .footer2 {
      padding: 5rem 4.8rem;
      .footer-logo {
        float: none;
        display: block;
        width: 32.7rem;
        height: 4.8rem;
        margin: 0 0 3rem;
        img {
          width: 100%;
        }
      }
      .addr {
        float: none;
        margin-top: 0;
        p {
          font-size: 2.2rem;
          line-height: 3.4rem;
          letter-spacing: -1px;
          padding: 2.2rem 0;
          border-bottom: 1px solid #f6f6f6;
          &:nth-last-child(1) {
            border-bottom: none;
            padding-bottom: 0;
          }
        }
        .line {
          display: inline-block;
          width: 1px;
          height: 1.8rem;
          margin: 0 2rem;
          background-color: #bcbcbc;
          &.pc-only {display: none;}
        }
      }
      .tel {
        float: none;
        width: 100%;
        padding: 4rem;
        border-radius: 2rem;
        background-color: #202124;
        .tel-title {
          font-size: 2.4rem;
          margin: 0 0 3rem;
        }
        .telno {
          float: left;
          font-size: 2.4rem;
          font-weight: bold;
          line-height: 3.8rem;
          padding-right: 4rem;

          &.no1 {
            margin-bottom: 0;
          }

          span {
            float: left;
            font-size: 2rem;
            line-height: 3.8rem;
            color: #f6f6f6;
            text-align: center;
            width: 7.2rem;
            height: 3.8rem;
            border-radius: 1.9rem;
            background-color: #35373b;
            margin-right: 1rem;
          }
        }
      }
    }
  }
}